import Axios from "axios";

const domain = process.env.VUE_APP_DOMAIN;
const name = "classes";

const axiosInstance = Axios.create({
    baseURL: domain + "/v1_pedagogico"
});

export const classesFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};


export const classesDisciplines = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/classes_disciplines`,
            params: payload,
        });
        return response.data;
    } catch (error) {
        return error;
    }
}


export const classesDisciplinesCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/classes_disciplines/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}


export const classesDisciplinesUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/classes_disciplines/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}


export const classesGet = async (id_school, id) => {
    try {
        id = parseInt(id)

        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/${id}`,
        });
        return response;
    } catch (error) {
        return error;
    }

}


export const classesCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/${name}/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const classesUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${name}/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const classesDeletex = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/${name}/delete/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const classesPaginate = async (id_school, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;

        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/paginate`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const classesRemove = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/${name}/remove/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const classesTrash = async (id_school, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/trash`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const classesRestore = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${name}/restore/${payload.id}`,
            data: {id: payload.id},
        });
        return response;
    } catch (error) {
        return error;
    }
};

//annual_planning/create

export const classesAnnualPlanningCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/annual_planning/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//annual_planning find
export const classesAnnualPlanningFind = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/annual_planning/classes/${payload.id_classes}/${payload.key}`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//classes_dailys
export const classesDailys = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/classes_dailys`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

// classes_dailys create
export const classesDailysCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/classes_dailys/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

// classes_dailys update
export const classesDailysUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/classes_dailys/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

// classes_dailys delete
export const classesDailysDelete = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/classes_dailys/delete/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments_types

export const assessmentsTypes = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/assessments_types`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments

export const assessments = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/assessments`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments_schedule

export const assessmentsSchedule = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/assessments_schedule`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments_schedule report

export const assessmentsScheduleReport = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/assessments_schedule/report`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//reports_cars

export const reportsCars = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/reports_cars`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//reports_cars create

export const reportsCarsCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/reports_cars/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//reports_cars update

export const reportsCarsUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/reports_cars/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}


//reports_cars_periods

export const reportsCarsPeriods = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/reports_cars_periods`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

// reports_cars_periods create

export const reportsCarsPeriodsCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/reports_cars_periods/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

// reports_cars_periods update

export const reportsCarsPeriodsUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/reports_cars_periods/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments_schedule create

export const assessmentsScheduleCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/assessments_schedule/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments_schedule delete

export const assessmentsScheduleDelete = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/assessments_schedule/delete/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments_applied create

export const assessmentsAppliedCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/assessments_applied/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments_applied update

export const assessmentsAppliedUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/assessments_applied/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}


//assessments create

export const assessmentsCreate = async (id_school, payload) => {
    try {
        const response =  axiosInstance({
            method: "post",
            url: `v1/${id_school}/assessments/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments update

export const assessmentsUpdate = async (id_school, payload) => {
    try {
        const id = payload.get('id')
        const response =  axiosInstance({
            method: "post",
            url: `v1/${id_school}/assessments/update/${id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments delete

export const assessmentsDelete = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/assessments/delete/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments find

export const assessmentsFind = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/assessments/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments paginate

export const assessmentsPaginate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/assessments/paginate`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments remove

export const assessmentsRemove = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/assessments/remove/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments trash

export const assessmentsTrash = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/assessments/trash`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments restore

export const assessmentsRestore = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/assessments/restore/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//calls_lists all

export const callsLists = async (id_school, payload) => {
    try {
        const response = axiosInstance({
            method: "get",
            url: `v1/${id_school}/calls_lists`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//calls_lists update

export const callsListsUpdate = async (id_school, payload) => {
    try {
        const id = payload.get('id')
        const response = axiosInstance({
            method: "post",
            url: `v1/${id_school}/calls_lists/update/${id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//assessments_applied all

export const assessmentsApplied = async (id_school, payload) => {
    try {
        const response = axiosInstance({
            method: "get",
            url: `v1/${id_school}/assessments_applied`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//class_contents create

export const classContentsCreate = async (id_school, payload) => {
    try {
        const response = axiosInstance({
            method: "post",
            url: `v1/${id_school}/class_contents/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//class_contents update

export const classContentsUpdate = async (id_school, payload) => {
    try {
        const response = axiosInstance({
            method: "put",
            url: `v1/${id_school}/class_contents/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}