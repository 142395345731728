const labels = {
  title: 'Tipo de período',
  plural: 'Tipos de período',
  upper: 'TIPO DE PERÍODO',
  upperPlural: 'TIPOS DE PERÍODO',
  lower: 'tipo de período',
  lowerPlural: 'tipos de período'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome",
      key: "label",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    type: {
      label: "Tipo",
      key: "type",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    label: null,
    identifier: null,
    type: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID`,
      example: "1",
    },
    label: {
      label: `Nome`,
      description: `Nome do período`,
      example: "Exemplo: Bimestral",
    },
    identifier: {
      label: "Identificador",
      description: `Identificador`,
      example: "Exemplo: bimestral",
    },
    type: {
      label: "Tipo de período",
      description: `...`,
      example: "DIA",
      options: [
        {value: 'DAY', text: "DIA"},
        {value: 'MONTH', text: "MÊS"},
        {value: 'YEARS', text: "ANO"},
      ]
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização`,
      example: "2021-01-01 00:00:00",
    }
  }
}
