import labels from "@/modules/stages/stages-labels";
export default {
    path: "/stages",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./stages.vue")
}

