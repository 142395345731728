import {cardsCommentsCreate, cardsCommentsDeletex, cardsCommentsFetch, cardsCommentsPaginate, cardsCommentsRemove, cardsCommentsRestore, cardsCommentsTrash, cardsCommentsUpdate} from "@/modules/cards-comments/cards-comments-service";

const state = {
  cardsComments: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getCardsComments(state) {
    console.log('getCardsComments',state.cardsComments);
    return state.cardsComments.data;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_CARDS_COMMENTS(state, cardsComments) {
    state.cardsComments = cardsComments;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getCardsComments({commit},payload) {
    try {
      const response = await cardsCommentsFetch(idSchool,payload);
      const {data} = response;
      commit("SET_CARDS_COMMENTS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsCommentsCreate({commit, dispatch}, payload) {
    try {
      //console.log('cardsCommentsCreate',payload);
      const response = await cardsCommentsCreate(idSchool, payload);
      commit("SET_LOAD", response.status);
      return true;
    } catch (error) {
      console.log(error);
        return false;
    }
  },
  async cardsCommentsUpdate({commit, dispatch}, payload) {
    try {
      const response = await cardsCommentsUpdate(idSchool, payload);
      commit("SET_LOAD", response.status);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async cardsCommentsDeletex({commit}, query) {
    try {
      const response = await cardsCommentsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsCommentsPaginate({commit}, query) {
    try {
      const response = await cardsCommentsPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async cardsCommentsRemove({commit}, payload) {
    try {
      const response = await cardsCommentsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async cardsCommentsTrash({commit}, query) {
    try {
      const response = await cardsCommentsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsCommentsRestore({commit}, payload) {
    try {
      const response = await cardsCommentsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
