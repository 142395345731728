import {itinerariesCreate, itinerariesDeletex, itinerariesFetch, itinerariesPaginate, itinerariesRemove, itinerariesRestore, itinerariesTrash, itinerariesUpdate} from "@/modules/itineraries/itineraries-service";

const state = {
  itineraries: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getItineraries(state) {
    const result = state.itineraries.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_ITINERARIES(state, itineraries) {
    state.itineraries = itineraries;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getItineraries({commit}) {
    try {
      const response = await itinerariesFetch(idSchool);
      //console.log('getItineraries',response);
      const {data} = response;
      commit("SET_ITINERARIES", data.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async itinerariesCreate({commit, dispatch}, payload) {
    try {
      //console.log('itinerariesCreate',payload);
      const response = await itinerariesCreate(idSchool, payload);
      dispatch("getItineraries", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async itinerariesUpdate({commit, dispatch}, payload) {
    try {
      const response = await itinerariesUpdate(idSchool, payload);
      dispatch("getItineraries", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async itinerariesDeletex({commit}, query) {
    try {
      const response = await itinerariesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async itinerariesPaginate({commit}, query) {
    try {
      const response = await itinerariesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async itinerariesRemove({commit}, payload) {
    try {
      const response = await itinerariesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async itinerariesTrash({commit}, query) {
    try {
      const response = await itinerariesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async itinerariesRestore({commit}, payload) {
    try {
      const response = await itinerariesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
