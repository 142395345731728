import {cardsLogsCreate, cardsLogsDeletex, cardsLogsFetch, cardsLogsPaginate, cardsLogsRemove, cardsLogsRestore, cardsLogsTrash, cardsLogsUpdate} from "@/modules/cards-logs/cards-logs-service";

const state = {
    cardsLogs: [],
    paginate: {},
    load: null,
};
const idSchool = 1;
const getters = {
    getCardsLogs(state) {
        return state.cardsLogs.data;
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
};

const mutations = {
    SET_CARDS_LOGS(state, cardsLogs) {
        state.cardsLogs = cardsLogs;
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getCardsLogs({commit},payload) {
        try {
            const response = await cardsLogsFetch(idSchool,payload);
            const {data} = response;
            commit("SET_CARDS_LOGS", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async cardsLogsCreate({commit, dispatch}, payload) {
        try {
            //console.log('cardsLogsCreate',payload);
            const response = await cardsLogsCreate(idSchool, payload);
            dispatch("getCardsLogs", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async cardsLogsUpdate({commit, dispatch}, payload) {
        try {
            const response = await cardsLogsUpdate(idSchool, payload);
            dispatch("getCardsLogs", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async cardsLogsDeletex({commit}, query) {
        try {
            const response = await cardsLogsDeletex(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async cardsLogsPaginate({commit}, query) {
        try {
            const response = await cardsLogsPaginate(idSchool, query);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async cardsLogsRemove({commit}, payload) {
        try {
            const response = await cardsLogsRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async cardsLogsTrash({commit}, query) {
        try {
            const response = await cardsLogsTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async cardsLogsRestore({commit}, payload) {
        try {
            const response = await cardsLogsRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
