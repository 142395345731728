const labels = {
  title: 'Especialidade de pessoa',
  plural: 'Especialidades de pessoas',
  upper: 'ESPECIALIDADE DE PESSOA',
  upperPlural: 'ESPECIALIDADES DE PESSOAS',
  lower: 'especialidade de pessoa',
  lowerPlural: 'especialidades de pessoas'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    peoples: {
      label: "Pessoa",
      key: "name",
    },
    peoples_specialties_types: {
      label: "Tipo de pessoa especial",
      key: "label",
    },
    level: {
      label: "Nível",
      key: "level",
    },
    diagnosis: {
      label: "Diagnóstico",
      key: "diagnosis",
    },
    diagnosis_file: {
      label: "Anexo do diagnóstico",
      key: "diagnosis_file",
    },
    diagnosis_date: {
      label: "Data do diagnóstico",
      key: "diagnosis_date",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    diagnosis_description: {
      label: "Descrição do diagnóstico",
      key: "diagnosis_description",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    id_peoples: null,
    id_peoples_specialties_types: null,
    level: null,
    diagnosis: null,
    diagnosis_file: null,
    diagnosis_date: null,
    description: null,
    diagnosis_description: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    id_peoples: {
      label: "Pessoa",
      description: `Pessoa`,
      example: "João",
    },
    id_peoples_specialties_types: {
      label: "Tipo de pessoa",
      description: `Tipo de pessoa especial`,
      example: "Pai/Mãe",
    },
    level: {
      label: `Nível`,
      description: `Nível do ${labels.lower}`,
      example: "Matutino",
    },
    identifier: {
      label: "Identificador",
      description: `Identificador do ${labels.lower}`,
      example: "Periodo da manhã",
    },
    type: {
      label: "Tipo de período",
      description: `...`,
      example: "1",
      options: [
        {value: 'DAY', text: "DIA"},
        {value: 'MONTH', text: "MÊS"},
        {value: 'YEARS', text: "ANO"},
      ]
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
