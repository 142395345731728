export default {
  title: 'Tipo de formulario',
  plural: 'Tipo de formulario',
  upper: 'TIPOS_DE_FORMULARIO',
  upperPlural: 'TIPOS_DE_FORMULARIOS',
  lower: 'tipos de formulário',
  lowerPlural: 'tipos de formulários',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome do tipos de formulário",
      key: "label",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados das tipos de formulários.",
  erroLoadTrash: "Erro ao carregar dados das tipos de formulários  para lixeira.",
  itemRemovidoLixeira: "Disciolina movida pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover tipos de formulário para a lixeira.",
  itemExcuidoLixeira: "Tipo de formulario excluída da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir tipos de formulário da lixeira.",
  itemRestauradoLixeira: "Tipo de formulario restaurada com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar tipos de formulário.",
  createTitle: "Novo tipos de formulário",
  create: "Tipo de formulario criada com sucesso.",
  update: "Tipo de formulario editada com sucesso.",
  updateTitle: "Editar tipos de formulário",
  createError: "Erro ao criar o tipos de formulário.",
  updateError: "Erro ao editar o tipos de formulário.",
  inputs: {
    id: null,
    label: null,
    identifier: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da tipos de formulário",
      example: "1",
    },
    label: {
      label: "Nome ",
      description: "Nome ",
      example: "Exemplo: Simples",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador",
      example: "Exemeplo: simples",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da disciplina",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da tipos de formulário",
      example: "2021-01-01 00:00:00",
    }
  }
}
