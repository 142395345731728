import {usersCreate, usersDeletex, usersFetch, usersPaginate, usersRemove, usersRestore, usersTrash, usersUpdate} from "@/modules/users/users-service";
import {allTwo} from "@/view/pages/peoples/peoples/services/services";

const state = {
  users: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getUsers(state) {
    const result = state.users.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getUsers({commit}) {
    try {
      const response = await usersFetch(idSchool);
      const {data} = response;
      commit("SET_USERS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async usersCreate({commit, dispatch}, payload) {
    try {
      //console.log('usersCreate',payload);
      const response = await usersCreate(idSchool, payload);
      dispatch("getUsers", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async usersUpdate({commit, dispatch}, payload) {
    try {
      const response = await usersUpdate(idSchool, payload);
      dispatch("getUsers", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async usersDeletex({commit}, query) {
    try {
      const response = await usersDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async usersPaginate({commit}, query) {
    try {
      const response = await usersPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async usersRemove({commit}, payload) {
    try {
      const response = await usersRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async usersTrash({commit}, query) {
    try {
      const response = await usersTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async usersRestore({commit}, payload) {
    try {
      const response = await usersRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
