export default {
  title: 'Valores padrão de campos',
  plural: 'Valores padrão de campos',
  upper: 'VALORES_PADRAO_DE_CAMPO',
  upperPlural: 'VALORES_PADRAO_DE_CAMPOS',
  lower: 'valores padrão de campos',
  lowerPlural: 'valores padrão de camposs',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome do campo",
      key: "label",
    },
    value: {
      label: "Valor do campo",
      key: "value",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    forms_fields: {
      label: "campo de formulário",
      key: "label",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados das valores padrão de camposs.",
  erroLoadTrash: "Erro ao carregar dados das valores padrão de camposs  para lixeira.",
  itemRemovidoLixeira: "Disciolina movida pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover valores padrão de campos para a lixeira.",
  itemExcuidoLixeira: "Valores padrão de campos excluída da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir valores padrão de campos da lixeira.",
  itemRestauradoLixeira: "Valores padrão de campos restaurada com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar valores padrão de campos.",
  createTitle: "Novo valores padrão de campos",
  create: "Valores padrão de campos criada com sucesso.",
  update: "Valores padrão de campos editada com sucesso.",
  updateTitle: "Editar valores padrão de campos",
  createError: "Erro ao criar a valores padrão de campos.",
  updateError: "Erro ao editar a valores padrão de campos.",
  inputs: {
    id: null,
    label: null,
    value: null,
    id_field: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID de valores padrão de campos",
      example: "1",
    },
    id_field: {
      label: "Campo do formulário",
      description: "ID do campo",
      example: "Exemplo: Texto",
    },
    label: {
      label: "Nome",
      description: "Nome do campo de valor",
      example: "Exemplo: Paises",
    },
    value: {
      label: "Valor",
      description: "Valor do campo",
      example: "Exemplo: Brasil",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última",
      example: "2021-01-01 00:00:00",
    }
  }
}
