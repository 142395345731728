import {
  fetchTrash,
  saveResponsiblesPeoplesTypes,
  fetchResponsiblesPeoplesTypes,
  removeResponsiblesPeoplesTypes,
  deleteResponsiblesPeoplesTypes,
  restoreResponsiblesPeoplesTypes,
  fetchAllResponsiblesPeoplesTypes,
} from "../services/services";
import formatToOptions from "../utils/formatToOptions";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  responsiblesPeoplesTypes: [],
  allResponsiblesPeoplesTypes: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  responsiblesPeoplesTypes(state) {
    return state.responsiblesPeoplesTypes;
  },
  isTrash(state) {
    return state.isTrash;
  },
  responsiblesTypesOptions(state) {
    return formatToOptions(state.allResponsiblesPeoplesTypes, "label", "id");
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_RESPONSIBLES_PEOPLES_TYPES(state, payload) {
    state.responsiblesPeoplesTypes = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_ALL_RESPONSIBLES_PEOPLES_TYPES(state, payload) {
    state.allResponsiblesPeoplesTypes = payload;
  },

  SET_RESPONSIBLES_PEOPLES_TYPES_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },
};

const actions = {
  async getResponsiblesPeoplesTypes({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchResponsiblesPeoplesTypes(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_RESPONSIBLES_PEOPLES_TYPES", response.data);
        commit(
          "SET_RESPONSIBLES_PEOPLES_TYPES_PAGINATION",
          response.pagination
        );
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getAllResponsiblesPeoplesTypes({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchAllResponsiblesPeoplesTypes(query);
      console.log(
        "🚀 ~ file: store.js:103 ~ getAllResponsiblesPeoplesTypes ~ response:",
        response
      );

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else commit("SET_ALL_RESPONSIBLES_PEOPLES_TYPES", response.data);
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_RESPONSIBLES_PEOPLES_TYPES", response.data);
        commit(
          "SET_RESPONSIBLES_PEOPLES_TYPES_PAGINATION",
          response.pagination
        );
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch, getters }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveResponsiblesPeoplesTypes(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getResponsiblesPeoplesTypes", {});
        else dispatch("getTrash", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removeResponsiblesPeoplesTypes(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getResponsiblesPeoplesTypes", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch, getters }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restoreResponsiblesPeoplesTypes(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getResponsiblesPeoplesTypes", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteResponsiblesPeoplesTypes(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getResponsiblesPeoplesTypes", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
