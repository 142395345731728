// import {
//   deleteItems,
//   fetchItems,
//   fetchTrash,
//   removeItems,
//   restoreItems,
//   saveItems,
// } from "../services/services.js";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  items: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  items(state) {
    return state.items;
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_ITEMS(state, payload) {
    state.items = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading= payload;
  },

  SET_ITEMS_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },
};

const actions = {
  async getItems({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      // const response = await fetchItems(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_ITEMS", response.data);
        commit("SET_ITEMS_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      // const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_ITEMS", response.data);
        commit("SET_ITEMS_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      // const response = await saveItems(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getItems", {});
        this.getItems();
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      // const response = await removeItems(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getPosts");
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      // const response = await restoreItems(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getPosts");
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      // const response = await deleteItems(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getItems");
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
