const labels = {
  title: 'Solicitação',
  plural: 'Solicitações',
  upper: 'SOLICITAÇÃO',
  upperPlural: 'SOLICITAÇÕES',
  lower: 'solicitação',
  lowerPlural: 'solicitações'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    title: {
      label: "Titulo de Solicitação",
      key: "title",
    },


    id_level: {
      label: "Nível de Solicitação",
      key: "id_level",
    },
    flag: {
      label: "Bandeira",
      key: "flag",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    stages: {
      label: "Estágio de Solicitação",
      key: "name",
    },
    peoples: {
      label: "Pessoa",
      key: "name",
    },
    cards_types: {
      label: "Tipo de Solicitação",
      key: "name",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira, existem dados vinculados a esse registro, remova os dados para excluir a solicitação.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    title: null,
    id_stage: null,
    id_people: null,
    id_type: null,
    id_level: null,
    flag: null,
    description: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    title: {
      label: `Titulo de ${labels.lower}`,
      description: `Titulo de ${labels.lower}`,
      example: "1",
    },
    id_stage: {
      label: `Estagio de ${labels.lower}`,
      description: `estagio de ${labels.lower}`,
      example: "1",
    },
    id_people: {
      label: "Pessoa",
      description: `pessoa de ${labels.lower}`,
      example: "1",
    },
    id_type: {
      label: `Tipo de ${labels.lower}`,
      description: `tipo de ${labels.lower}`,
      example: "1",
    },
    id_level: {
      label: `Nível de ${labels.lower}`,
      description: `Nível do ${labels.lower}`,
      example: "1",
    },
    flag: {
      label: `Bandeira de ${labels.lower}`,
      description: `Bandeira de ${labels.lower}`,
      example: "1",
    },
    description: {
      label: `Descrição de ${labels.lower}`,
      description: `Descrição de ${labels.lower}`,
      example: "Solitação de curso",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
