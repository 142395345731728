import labels from "@/modules/calendars/calendars-labels";
export default {
    path: "/calendars",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./calendars.vue")
}

