import {cardsCreate, cardsDeletex, cardsFetch, cardsPaginate, cardsRemove, cardsRestore, cardsTrash, cardsUpdate} from "@/modules/cards/cards-service";

const state = {
  cards: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getCards(state) {
    const result = state.cards.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_CARDS(state, cards) {
    state.cards = cards;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getCards({commit}) {
    try {
      const response = await cardsFetch(idSchool);
      const {data} = response;
      commit("SET_CARDS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsCreate({commit, dispatch}, payload) {
    try {
      //console.log('cardsCreate',payload);
      const response = await cardsCreate(idSchool, payload);
      dispatch("getCards", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsCreateSimple({commit, dispatch}, payload) {
    try {
      //console.log('cardsCreate',payload);
      const response = await cardsCreate(idSchool, payload);
      commit("SET_LOAD", response.status);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async cardsUpdate({commit, dispatch}, payload) {
    try {
      const response = await cardsUpdate(idSchool, payload);
      dispatch("getCards", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsUpdateSimple({commit, dispatch}, payload) {
    try {
      const response = await cardsUpdate(idSchool, payload);
      commit("SET_LOAD", response.status);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async cardsDeletex({commit}, query) {
    try {
      const response = await cardsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsPaginate({commit}, query) {
    try {
      const response = await cardsPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async cardsRemove({commit}, payload) {
    try {
      const response = await cardsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async cardsTrash({commit}, query) {
    try {
      const response = await cardsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsRestore({commit}, payload) {
    try {
      const response = await cardsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
