import labels from "@/modules/profiles/profiles-labels";
export default {
    path: "/profiles",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./profiles.vue")
}

