import {payInvoicesCreate, payInvoicesDeletex, payInvoicesFetch, payInvoicesPaginate, payInvoicesRemove, payInvoicesRestore, payInvoicesTrash, payInvoicesUpdate} from "@/modules/pay-invoices/pay-invoices-service";

const state = {
  payInvoices: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getPayInvoices(state) {
    const result = state.payInvoices.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_PAY_INVOICES(state, payInvoices) {
    state.payInvoices = payInvoices;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getPayInvoices({commit}) {
    try {
      const response = await payInvoicesFetch(idSchool);
      const {data} = response;
      commit("SET_PAY_INVOICES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesCreate({commit, dispatch}, payload) {
    try {
      //console.log('payInvoicesCreate',payload);
      const response = await payInvoicesCreate(idSchool, payload);
      dispatch("getPayInvoices", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesUpdate({commit, dispatch}, payload) {
    try {
      const response = await payInvoicesUpdate(idSchool, payload);
      dispatch("getPayInvoices", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesDeletex({commit}, query) {
    try {
      const response = await payInvoicesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesPaginate({commit}, query) {
    try {
      const response = await payInvoicesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async payInvoicesRemove({commit}, payload) {
    try {
      const response = await payInvoicesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async payInvoicesTrash({commit}, query) {
    try {
      const response = await payInvoicesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesRestore({commit}, payload) {
    try {
      const response = await payInvoicesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
