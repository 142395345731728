import Axios from "axios";

const REQUEST =
    `${process.env.VUE_APP_DOMAIN}/v2_post/v1/1/`;

const axiosInstance = Axios.create();

export const fetchPosts = async (payload) => {


    try {
        const {data} = await axiosInstance({
            baseURL: REQUEST,
            method: "get",
            url: `posts/paginate`,
            params: payload
        });

        for (const item of data.data) {
            item.image_view = item.image;
            item.video_view = item.video;
            item.audio_view = item.audio;
        }

        return data;
    } catch (error) {
        return error;
    }
};

export const fetchPostsTrash = async (payload) => {

    try {
        const {data} = await axiosInstance({
            baseURL: REQUEST,
            method: "get",
            url: `posts/trash`,
            params: payload
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const fetchPostsTypes = async () => {
    try {
        const {data} = await axiosInstance({
            method: "get",
            url: `posts/type`,
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const savePost = async (payload) => {

    payload.id_user = 1;

    try {

        const form = new FormData();
        Object.keys(payload).forEach((key) => {

            // if object payload[key]
            if (typeof payload[key] === "object" && payload[key] && key !== "image" && key !== "video" && key !== "audio") {
                form.append(key, JSON.stringify(payload[key]));
            } else if (key === "tags") {
            } else if (payload[key]) {
                if (key === "image" || key === "video" || key === "audio") {
                    form.append(key, payload[key]);
                } else {
                    form.append(key, payload[key]);
                }
            } else {
                form.append(key, "");
            }
        });


        const {data} = await axiosInstance({
            baseURL: REQUEST,
            method: !payload.id ? "post" : "put",
            url: !payload.id ? "posts/create" : `posts/update/${payload.id}`,
            data: form,
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const removePost = async (payload) => {
    try {
        if (payload.hasOwnProperty("id_post_copy")) delete payload.id_post_copy;

        payload.id_post_parent = 1;
        payload.id_user = 1;
        payload.order = parseInt(payload.order);

        const {data} = await axiosInstance({
            baseURL: REQUEST,
            method: "put",
            url: "posts/remove",
            data: {...payload},
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const restorePost = async (payload) => {
    try {
        if (payload.hasOwnProperty("id_post_copy")) delete payload.id_post_copy;

        payload.order = parseInt(payload.order);
        payload.id_post_parent = 1;
        payload.id_user = 1;

        const {data} = await axiosInstance({
            baseURL: REQUEST,
            method: "put",
            url: "posts/restore",
            data: {...payload},
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const deletePost = async (payload) => {
    try {
        if (payload.hasOwnProperty("id_post_copy")) delete payload.id_post_copy;

        payload.id_post_parent = 1;
        payload.id_user = 1;
        payload.order = parseInt(payload.order);

        const {data} = await axiosInstance({
            baseURL: REQUEST,
            method: "delete",
            url: "posts/delete",
            data: {...payload},
        });

        return data;
    } catch (error) {
        return error;
    }
};
