import { getFirebaseBackend } from "../../helpers/firebase/authUtils";

const state = {
  currentUser: sessionStorage.getItem("authUser"),
  loginStatus: false,
  statusRequest: false,
  errorMessage: "",
  codeVerify: false,
};

const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState("auth.currentUser", newValue);
  },
  SET_LOGGED_STATUS(state, newValue) {
    state.loginStatus = newValue;
  },
  SET_ERROR_MESSAGE(state, newValue) {
    state.errorMessage = newValue;
  },
  SET_STATUS_REQUEST(state, newValue) {
    state.statusRequest = newValue;
  },
  SET_CODE_VERIFY(state, newValue) {
    state.codeVerify = newValue;
  },
};

const getters = {
  loggedIn(state) {
    return !!state.loginStatus;
  },
  errorMessage(state) {
    return state.errorMessage;
  },
  statusRequest() {
    return state.statusRequest;
  },
  errorMessage() {
    return state.errorMessage;
  },
  codeVerify() {
    return state.codeVerify;
  },
};

const actions = {
  async verifyCode({ commit }, { code } = {}) {
    try {
      const response = await getFirebaseBackend().verifyPasswordResetCode(code);
      commit("SET_CODE_VERIFY", true);
    } catch (e) {
      commit("SET_CODE_VERIFY", false);
    }
  },

  confirmPasswordReset({ commit }, { code, newPassword } = {}) {
    try {
      getFirebaseBackend().confirmPasswordReset(code, newPassword);
      commit("SET_STATUS_REQUEST", true);
    } catch (e) {
      commit("SET_STATUS_REQUEST", false);
    }
  },

  init({ state, dispatch }) {
    dispatch("validate");
  },

  // Logs in the current user.
  async logIn({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    try {
      await getFirebaseBackend()
        .loginUser(email, password)
        .then((response) => {
          const user = response;
          commit("SET_CURRENT_USER", user);
          return user;
        });
      commit("SET_LOGGED_STATUS", true);
    } catch (error) {
      commit("SET_LOGGED_STATUS", false);
    }
  },

  // Logs out the current user.
  logOut({ commit }) {
    // eslint-disable-next-line no-unused-vars
    commit("SET_CURRENT_USER", null);
    commit("SET_LOGGED_STATUS", false);
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-unused-vars
      getFirebaseBackend()
        .logout()
        .then((response) => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  },

  // register the user
  register({ commit, dispatch, getters }, { email, password } = {}) {
    if (getters.loggedIn) return dispatch("validate");

    return getFirebaseBackend()
      .registerUser(email, password)
      .then((response) => {
        const user = response;
        commit("SET_CURRENT_USER", user);
        return user;
      });
  },

  // register the user
  // eslint-disable-next-line no-unused-vars
  async resetPassword({ commit, dispatch, getters }, { email } = {}) {
    if (getters.loggedIn) return dispatch("validate");
    try {
      await getFirebaseBackend()
        .forgetPassword(email)
        .then(() => {
          commit("SET_STATUS_REQUEST", true);
        });
    } catch (error) {
      commit("SET_STATUS_REQUEST", false);
      commit("SET_ERROR_MESSAGE", error.code);
    }
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null);

    const user = getFirebaseBackend().getAuthenticatedUser();

    commit("SET_CURRENT_USER", user);
    commit("SET_LOGGED_STATUS", true);

    return user;
  },
};

// ===
// Private helpers
// ===

const saveState = (key, state) => {
  window.localStorage.setItem(key, JSON.stringify(state));
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
