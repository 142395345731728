import {
    citiesFetch,
    notesCreate,
    notesFetch,
    notesRemove,
    notesTypesFetch,
    notesUpdate,
    peoplesAddressCreate,
    peoplesAddressDelete,
    peoplesAddressFetch,
    peoplesAddressPaginate,
    peoplesAddressRemove,
    peoplesAddressRestore,
    peoplesAddressTrash,
    peoplesAddressUpdate,
    peoplesDefaultsCreate,
    peoplesDefaultsFetch, peoplesDefaultsUpdate,
    peoplesDocumentsCreate,
    peoplesDocumentsFetch,
    peoplesDocumentsRemove,
    peoplesDocumentsTypesFetch,
    peoplesDocumentsUpdate, peoplesExtrasCreate, peoplesExtrasFetch, peoplesExtrasRemove, peoplesExtrasTypesFetch, peoplesExtrasUpdate,
    peoplesResponsibleCreate,
    peoplesResponsibleFetch,
    peoplesResponsibleRemove,
    peoplesResponsibleTypesFetch,
    peoplesResponsibleUpdate,
    peoplesSpecialtiesCreate,
    peoplesSpecialtiesFetch,
    peoplesSpecialtiesRemove,
    peoplesSpecialtiesTypesFetch,
    peoplesSpecialtiesUpdate,
    peoplesTelephonesCreate,
    peoplesTelephonesFetch,
    peoplesTelephonesRemove,
    peoplesTelephonesTypesFetch,
    peoplesTelephonesUpdate,
    statesFetch,
    viaCep
} from "@/components/student/StudentService";

const state = {
    peoplesAddress: [],
    states: [],
    cities: [],
    telephonesTypes: [],
    responsibleTypes: [],
    responsible: [],
    specialtiesTypes: [],
    specialties: [],
    documentsTypes: [],
    documents: [],
    notesTypes: [],
    notes: [],
    defaults: [],
    extrasTypes: [],
    extras: [],
    telephones: [],
    paginate: {},
    load: null,
};
const idSchool = 1;
const getters = {
    getPeoplesAddress(state) {
        return state.peoplesAddress.data;
    },
    getStates(state) {
        return state.states;
    },
    getCities(state) {
        return state.cities;
    },
    getTelephonesTypes(state) {
        return state.telephonesTypes;
    },
    getNotes(state) {
        return state.notes;
    },
    getExtras(state) {
        return state.extras;
    },
    getExtrasTypes(state) {
        return state.extrasTypes;
    },
    getResponsibles(state) {
        return state.responsible;
    },
    getNotesTypes(state) {
        return state.notesTypes;
    },
    getResponsibleTypes(state) {
        return state.responsibleTypes;
    },
    getDocumentsTypes(state) {
        return state.documentsTypes;
    },
    getSpecialties(state) {
        return state.specialties;
    },
    getDocuments(state) {
        return state.documents;
    },
    getTelephones(state) {
        return state.telephones;
    },
    getSpecialtiesTypes(state) {
        return state.specialtiesTypes;
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
    getDefaults(state) {
        return state.defaults;
    },

};

const mutations = {
    SET_CARDS(state, peoplesAddress) {
        state.peoplesAddress = peoplesAddress;
    },
    SET_STATE(state, paginate) {
        state.states = paginate;
    },
    SET_CITIES(state, paginate) {
        state.cities = paginate;
    },
    SET_TEL_TYPES(state, paginate) {
        state.telephonesTypes = paginate;
    },
    SET_TELEPHONES(state, paginate) {
        state.telephones = paginate;
    },
    SET_RES_TYPE(state, paginate) {
        state.responsibleTypes = paginate;
    },
    SET_RESPONSIBLE(state, paginate) {
        state.responsible = paginate;
    },
    SET_SPE_TYPE(state, paginate) {
        state.specialtiesTypes = paginate;
    },
    SET_SPECIALTIES(state, paginate) {
        state.specialties = paginate;
    },
    SET_DOC_TYPE(state, paginate) {
        state.documentsTypes = paginate;
    },
    SET_DOCS(state, paginate) {
        state.documents = paginate;
    },
    SET_NOTES_TYPES(state, paginate) {
        state.notesTypes = paginate;
    },
    SET_NOTES(state, paginate) {
        state.notes = paginate;
    },
    SET_EXTRAS(state, paginate) {
        state.extras = paginate;
    },
    SET_DEFAULTS(state, paginate) {
        state.defaults = paginate;
    },
    SET_EXTRA_TYPE(state, paginate) {
        state.extrasTypes = paginate;
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getPeoplesAddress({commit}, payload) {
        try {
            const response = await peoplesAddressFetch(idSchool, payload);
            const {data} = response;
            commit("SET_CARDS", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async peoplesAddressCreate({commit, dispatch}, payload) {
        try {
            //console.log('peoplesAddressCreate', payload);
            const response = await peoplesAddressCreate(idSchool, payload);
            dispatch("getPeoplesAddress", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async peoplesAddressUpdate({commit, dispatch}, payload) {
        try {
            const response = await peoplesAddressUpdate(idSchool, payload);
            dispatch("getPeoplesAddress", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async peoplesAddressDelete({commit}, query) {
        try {
            const response = await peoplesAddressDelete(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async peoplesAddressPaginate({commit}, query) {
        try {
            const response = await peoplesAddressPaginate(idSchool, query);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async peoplesAddressRemove({commit}, payload) {
        try {
            const response = await peoplesAddressRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async peoplesAddressTrash({commit}, query) {
        try {
            const response = await peoplesAddressTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async peoplesAddressRestore({commit}, payload) {
        try {
            const response = await peoplesAddressRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async statesFetch({commit}) {
        try {
            const response = await statesFetch(idSchool);
            commit("SET_STATE", response.data.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }

    },
    async citiesFetch({commit}, id_state) {
        try {
            const response = await citiesFetch(idSchool, id_state);
            commit("SET_CITIES", response.data.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }


    },
    async viaCepFetch({commit}, cep) {
        try {
            return viaCep(cep);
        } catch (error) {
            return error;
        }

    },
    // peoplesTelephonesTypesFetch
    async peoplesTelephonesTypesFetch({commit}) {
        try {
            const response = await peoplesTelephonesTypesFetch(idSchool);
            commit("SET_TEL_TYPES", response.data.data);
        } catch (error) {
            console.log(error);
        }
    },

    // peoplesTelephonesFetch
    async peoplesTelephonesFetch({commit}, payload) {
        try {
            const response = await peoplesTelephonesFetch(idSchool, payload);
            commit("SET_TELEPHONES", response.data.data);
        } catch (error) {
            return error;
        }
    },
    // peoplesTelephonesCreate
    async peoplesTelephonesCreate({commit}, payload) {
        return peoplesTelephonesCreate(idSchool, payload);
    },
    // peoplesTelephonesUpdate
    async peoplesTelephonesUpdate({commit}, payload) {
        return peoplesTelephonesUpdate(idSchool, payload);
    },
    // peoplesTelephonesRemove
    async peoplesTelephonesRemove({commit}, payload) {
        return peoplesTelephonesRemove(idSchool, payload);
    },
    //peoplesResponsibleTypesFetch
    async peoplesResponsibleTypesFetch({commit}) {
        try {
            const response = await peoplesResponsibleTypesFetch(idSchool);
            commit("SET_RES_TYPE", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },
    // peoplesResponsibleFetch
    async peoplesResponsibleFetch({commit}, payload) {
        try {
            const response = await peoplesResponsibleFetch(idSchool, payload);
            commit("SET_RESPONSIBLE", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },
    // peoplesResponsibleCreate
    async peoplesResponsibleCreate({commit}, payload) {
        return peoplesResponsibleCreate(idSchool, payload);
    },
    // peoplesResponsibleUpdate
    async peoplesResponsibleUpdate({commit}, payload) {
        return peoplesResponsibleUpdate(idSchool, payload);
    },
    // peoplesResponsibleRemove
    async peoplesResponsibleRemove({commit}, payload) {
        return peoplesResponsibleRemove(idSchool, payload);
    },

    //peoplesSpecialtiesTypesFetch
    async peoplesSpecialtiesTypesFetch({commit}) {
        try {
            const response = await peoplesSpecialtiesTypesFetch(idSchool);
            commit("SET_SPE_TYPE", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },
    //peoples_specialties

    async peoplesSpecialtiesFetch({commit}, payload) {
        try {
            const response = await peoplesSpecialtiesFetch(idSchool, payload);
            commit("SET_SPECIALTIES", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },

    //peoplesSpecialtiesCreate
    async peoplesSpecialtiesCreate({commit}, payload) {
        return peoplesSpecialtiesCreate(idSchool, payload);
    },
    //peoplesSpecialtiesUpdate
    async peoplesSpecialtiesUpdate({commit}, payload) {
        return peoplesSpecialtiesUpdate(idSchool, payload);
    },
    //peoplesSpecialtiesRemove
    async peoplesSpecialtiesRemove({commit}, payload) {
        return peoplesSpecialtiesRemove(idSchool, payload);
    },

    //peoplesDocumentsTypesFetch
    async peoplesDocumentsTypesFetch({commit}) {
        try {
            const response = await peoplesDocumentsTypesFetch(idSchool);
            commit("SET_DOC_TYPE", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },
    //peoplesDocumentsFetch
    async peoplesDocumentsFetch({commit}, payload) {
        try {
            const response = await peoplesDocumentsFetch(idSchool, payload);
            commit("SET_DOCS", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },
    //peoplesDocumentsSave
    async peoplesDocumentsCreate({commit}, payload) {
        return peoplesDocumentsCreate(idSchool, payload);
    },
    //peoplesDocumentsUpdate
    async peoplesDocumentsUpdate({commit}, payload) {
        return peoplesDocumentsUpdate(idSchool, payload);
    },
    //peoplesDocumentsRemove
    async peoplesDocumentsRemove({commit}, payload) {
        return peoplesDocumentsRemove(idSchool, payload);
    },
    //notesTypesFetch
    async notesTypesFetch({commit}) {
        try {
            const response = await notesTypesFetch(idSchool);
            commit("SET_NOTES_TYPES", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },
    // notesFetch
    async notesFetch({commit}, payload) {
        try {
            const res = await notesFetch(idSchool, payload);
            commit("SET_NOTES", res.data.data);
            return res;
        } catch (error) {
            return error;
        }
    },
    // notesCreate
    async notesCreate({commit}, payload) {
        return notesCreate(idSchool, payload);
    },
    // notesUpdate
    async notesUpdate({commit}, payload) {
        return notesUpdate(idSchool, payload);
    },
    // notesRemove
    async notesRemove({commit}, payload) {
        return notesRemove(idSchool, payload);
    },
    //peoplesDefaultsFetch
    async peoplesDefaultsFetch({commit}, payload) {
        try {
            const response = await peoplesDefaultsFetch(idSchool, payload);
            commit("SET_DEFAULTS", response.data.data);
            return response.data.data;
        } catch (error) {
            return error;
        }
    },
    //peoplesDefaultsSave
    async peoplesDefaultsCreate({commit}, payload) {
        return peoplesDefaultsCreate(idSchool, payload);
    },
    //peoplesDefaultsUpdate
    async peoplesDefaultsUpdate({commit}, payload) {
        return peoplesDefaultsUpdate(idSchool, payload);
    },
    //peoples_extras_types
    async peoplesExtrasTypesFetch({commit}) {
        try {
            const response = await peoplesExtrasTypesFetch(idSchool);
            commit("SET_EXTRA_TYPE", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },

    //peoples_extras
    async peoplesExtrasFetch({commit}, payload) {
        try {
            const response = await peoplesExtrasFetch(idSchool, payload);
            commit("SET_EXTRAS", response.data.data);
            return response;
        } catch (error) {
            return error;
        }
    },
    //peoples_extras_save
    async peoplesExtrasCreate({commit}, payload) {
        return peoplesExtrasCreate(idSchool, payload);
    },
    //peoples_extras_update
    async peoplesExtrasUpdate({commit}, payload) {
        return peoplesExtrasUpdate(idSchool, payload);
    },

    //peoples_extras_remove
    async peoplesExtrasRemove({commit}, payload) {
        return peoplesExtrasRemove(idSchool, payload);
    },
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
