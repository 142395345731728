export default {
  title: 'Grade',
  plural: 'Grades',
  upper: 'GRADE',
  upperPlural: 'GRADES',
  lower: 'grade',
  lowerPlural: 'grades',
  headers: {
    id : {
      label: "ID",
      key: "id",
    },
    year : {
      label: "Ano",
      key: "year",
    },
    // date_start : {
    //   label: "Data de início",
    //   key: "date_start",
    // },
    // date_end : {
    //   label: "Data de fim",
    //   key: "date_end",
    // },
    // total_hours : {
    //   label: "Total de horas",
    //   key: "total_hours",
    // },
    // total_hours_days : {
    //   label: "Total de horas por dia",
    //   key: "total_hours_days",
    // },
    description : {
      label: "Descrição",
      key: "description",
    },
    title : {
      label: "Título",
      key: "title",
    },
    created_at : {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at : {
      label: "Última atualização",
      key: "updated_at",
    },
    teachings : {
      label: "Segmento",
      key: "name",
    },

  },
  erroLoadPaginate: "Erro ao carregar dados dos currículos.",
  erroLoadTrash: "Erro ao carregar dados dos currículos para  lixeira.",
  itemRemovidoLixeira: "Currículo movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover o currículo para a lixeira.",
  itemExcuidoLixeira: "Currículo excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir o currículo da lixeira, existem dados vinculados a esse registro, remova os dados para excluir a grade.",
  itemRestauradoLixeira: "Currículo restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar o currículo.",
  createTitle: "Nova grade",
  create: "Curriculo criado com sucesso.",
  update: "Curriculos editado com sucesso.",
  updateTitle: "Editar currículo",
  createError: "Erro ao criar o currículo.",
  updateError: "Erro ao editar o currículo.",
  inputs: {id: null,
    year: null,
    date_start: null,
    date_end: null,
    total_hours: null,
    total_hours_days: null,
    description: null,
    id_teachings: null,
    title: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID do currículo",
      example: "1",
    },
    year: {
      label: "Ano",
      description: "Ano do currículo",
      example: "2021",
    },
    date_start: {
      label: "Data de início",
      description: "Data de início do currículo",
      example: "2021-01-01",
    },
    date_end: {
      label: "Data de fim",
      description: "Data de fim do currículo",
      example: "2021-12-31",
    },
    total_hours: {
      label: "Total de horas",
      description: "Total de horas do currículo",
      example: "1000",
    },
    total_hours_days: {
      label: "Total de horas por dia",
      description: "Total de horas por dia do currículo ",
      example: "5",
    },
    description: {
      label: "Descrição",
      description: "Descrição do currícilo",
      example: "Descrição do currículo",
    },
    id_teachings: {
      label: "Segmento",
      description: "Segmento do currículo",
      example: "1",
    },
    title: {
      label: "Título",
      description: "Título do currículo",
      example: "Título do currículo",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação do currículo",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização do currículo",
      example: "2021-01-01 00:00:00",
    }
  }
}
