import {trailsCreate, trailsDeletex, trailsFetch, trailsPaginate, trailsRemove, trailsRestore, trailsTrash, trailsUpdate} from "@/modules/trails/trails-service";

const state = {
  trails: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getTrails(state) {
    const result = state.trails.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_TRAILS(state, trails) {
    state.trails = trails;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getTrails({commit}) {
    try {
      const response = await trailsFetch(idSchool);
      const {data} = response;
      commit("SET_TRAILS", data.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async trailsCreate({commit, dispatch}, payload) {
    try {
      //console.log('trailsCreate',payload);
      const response = await trailsCreate(idSchool, payload);
      dispatch("getTrails", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async trailsUpdate({commit, dispatch}, payload) {
    try {
      const response = await trailsUpdate(idSchool, payload);
      dispatch("getTrails", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async trailsDeletex({commit}, query) {
    try {
      const response = await trailsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async trailsPaginate({commit}, query) {
    try {
      const response = await trailsPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async trailsRemove({commit}, payload) {
    try {
      const response = await trailsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async trailsTrash({commit}, query) {
    try {
      const response = await trailsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async trailsRestore({commit}, payload) {
    try {
      const response = await trailsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
