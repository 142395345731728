import labels from "@/modules/pay-methods/pay-methods-labels";
export default {
    path: "/pay-methods",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./pay-methods.vue")
}

