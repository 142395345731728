export default {
  title: 'Capacidade de alunos por nível no ano',
  plural: 'Capacidade de alunos por nível no ano',
  upper: 'CAPACIDADE DE ALUNOS POR NÍVEL NO ANO',
  upperPlural: 'CAPACIDADE DE ALUNOS POR NÍVEL NO ANO',
  lower: 'capacidade de alunos por nível no ano',
  lowerPlural: 'capacidade de alunos por nível no ano',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    year: {
      label: "Ano",
      key: "year",
    },
    qtd: {
      label: "Quantidade",
      key: "qtd",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    levels: {
      label: "Nível",
      key: "label",
    },
  },
  erroLoadPaginate: "Erro ao carregar capacidades de alunos por nível no ano.",
  erroLoadTrash: "Erro ao carregar Capacidades de alunos por nível no ano lixeira.",
  itemRemovidoLixeira: "Capacidade de alunos por nível no ano movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover a Capacidade de alunos por nível no ano para a lixeira.",
  itemExcuidoLixeira: "Capacidade de alunos por nível no ano excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir Capacidade de alunos por nível no ano da lixeira.",
  itemRestauradoLixeira: "Capacidade de alunos por nível no ano restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Capacidade de alunos por nível no ano ao restaurar o item.",
  createTitle: "Nova Capacidade de alunos por nível no ano",
  create: "Capacidade de alunos por nível no ano criada com sucesso.",
  update: "Capacidade de alunos por nível no ano editada com sucesso.",
  updateTitle: "Editar Capacidade de alunos por nível no ano",
  createError: "Erro ao criar a Capacidade de alunos por nível no ano.",
  updateError: "Erro ao editar Capacidade de alunos por nível no ano.",
  inputs: {
    id: null,
    id_level: null,
    year: null,
    qtd: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da capacidade de alunos por nível no ano",
      example: "1",
    },
    id_level: {
      label: "Nível",
      description: " Capacidade de alunos por nível no ano",
      example: "1˚ ano",
    },
    year: {
      label: "Ano",
      description: "Ano da capacidade de alunos por nível no ano",
      example: "2021",
    },
    qtd: {
      label: "Quantidade",
      description: "Quantidade de alunos por nível no ano",
      example: "30",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação Capacidade de alunos por nível no ano",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da Capacidade de alunos por nível no ano",
      example: "2021-01-01 00:00:00",
    }
  }
}
