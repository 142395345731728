import {formsFieldsTypesCreate, formsFieldsTypesDeletex, formsFieldsTypesFetch, formsFieldsTypesPaginate, formsFieldsTypesRemove, formsFieldsTypesRestore, formsFieldsTypesTrash, formsFieldsTypesUpdate} from "@/modules/forms-fields-types/forms-fields-types-service";

const state = {
  formsFieldsTypes: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getFormsFieldsTypes(state) {
    const result = state.formsFieldsTypes.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_FORMS_FIELDS_TYPES(state, formsFieldsTypes) {
    state.formsFieldsTypes = formsFieldsTypes;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      //TODO: ajustar paginate.data
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getFormsFieldsTypes({commit}) {
    try {
      const response = await formsFieldsTypesFetch(idSchool);
      const {data} = response;
      commit("SET_FORMS_FIELDS_TYPES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsFieldsTypesCreate({commit, dispatch}, payload) {
    try {
      //console.log('formsFieldsTypesCreate',payload);
      const response = await formsFieldsTypesCreate(idSchool, payload);
      dispatch("getFormsFieldsTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsFieldsTypesUpdate({commit, dispatch}, payload) {
    try {
      const response = await formsFieldsTypesUpdate(idSchool, payload);
      dispatch("getFormsFieldsTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsFieldsTypesDeletex({commit}, query) {
    try {
      const response = await formsFieldsTypesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsFieldsTypesPaginate({commit}, query) {
    try {
      const response = await formsFieldsTypesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async formsFieldsTypesRemove({commit}, payload) {
    try {
      const response = await formsFieldsTypesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async formsFieldsTypesTrash({commit}, query) {
    try {
      const response = await formsFieldsTypesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsFieldsTypesRestore({commit}, payload) {
    try {
      const response = await formsFieldsTypesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
