const labels = {
  title: 'Formulário',
  plural: 'Formulários',
  upper: 'Formulário',
  upperPlural: 'FormulárioS',
  lower: 'Formulário',
  lowerPlural: 'Formulários'
}

export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    label: {
      label: "Nome do Formulário",
      key: "label",
    },
    id: {
      label: "ID do Formulário",
      key: "id",
    },
    identifier: {
      label: "Identificação",
      key: "identifier",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    before: {
      label: "Antes",
      key: "before",
    },
    after: {
      label: "Depois",
      key: "after",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    forms_modules: {
      label: "Modulo de Formulário",
      key: "label",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados dos Formulários.",
  erroLoadTrash: "Erro ao carregar dados dos Formulários  para lixeira.",
  itemRemovidoLixeira: "Formulário movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover Formulário para a lixeira.",
  itemExcuidoLixeira: "Formulário excluída da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir Formulário da lixeira.",
  itemRestauradoLixeira: "Formulário restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar Formulário.",
  createTitle: "Novo Formulário",
  create: "Formulário criado com sucesso.",
  update: "Formulário editado com sucesso.",
  updateTitle: "Editar Formulário",
  createError: "Erro ao criar o Formulário.",
  updateError: "Erro ao editar o Formulário.",
  inputs: {id: null, label: "", identifier: "", description: "", before: "", after: "", id_module: ""},
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID do Formulário",
      example: "1",
    },
    label: {
      label: "Nome do Formulário",
      description: "Nome do Formulário",
      example: "formulário",
    },
    identifier: {
      label: "Identificação do Formulário",
      description: "Identificação do Formulário",
      example: "formulário",
    },
    description: {
      label: "Descrição",
      description: "Descrição do Formulário",
      example: "Descrição de formulário",
    },
    before: {
      label: "Antes",
      description: "Antes do Formulário",
      example: "texto",
    },
    after: {
      label: "Depois",
      description: "Depois do Formulário",
      example: "texto 2",
    },
    id_module: {
      label: "Modulo de Formulário",
      description: "Modulo de Formulário",
      example: "1",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação do Formulário",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização do Formulário",
      example: "2021-01-01 00:00:00",
    }
  }
}
