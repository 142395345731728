import {cardsFilesCreate, cardsFilesDeletex, cardsFilesFetch, cardsFilesPaginate, cardsFilesRemove, cardsFilesRestore, cardsFilesTrash, cardsFilesUpdate} from "@/modules/cards-files/cards-files-service";

const state = {
  cardsFiles: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getCardsFiles(state) {
    return state.cardsFiles.data;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_CARDS_FILES(state, cardsFiles) {
    state.cardsFiles = cardsFiles;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getCardsFiles({commit}) {
    try {
      const response = await cardsFilesFetch(idSchool);
      const {data} = response;
      commit("SET_CARDS_FILES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsFilesCreate({commit, dispatch}, payload) {
    try {
      const response = await cardsFilesCreate(idSchool, payload);
      commit("SET_LOAD", response.status);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async cardsFilesUpdate({commit, dispatch}, payload) {
    try {
      const response = await cardsFilesUpdate(idSchool, payload);
      commit("SET_LOAD", response.status);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async cardsFilesDeletex({commit}, query) {
    try {
      const response = await cardsFilesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async cardsFilesPaginate({commit}, query) {
    try {
      const response = await cardsFilesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async cardsFilesRemove({commit}, payload) {
    try {
      const response = await cardsFilesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async cardsFilesTrash({commit}, query) {
    try {
      const response = await cardsFilesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsFilesRestore({commit}, payload) {
    try {
      const response = await cardsFilesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
