export default {
  title: 'Campos de formularios',
  plural: 'Campos de formularios',
  upper: 'CAMPOS_DE_FORMULARIOS',
  upperPlural: 'CAMPOS_DE_FORMULARIOS',
  lower: 'campos de formularios',
  lowerPlural: 'campos de formularios',
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome do campo",
      key: "label",
    },
    class: {
      label: "classe",
      key: "class",
    },
    order: {
      label: "Ordem",
      key: "order",
    },
    placeholder: {
      label: "Espaço reservado",
      key: "placeholder",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    forms_fields_types: {
      label: "Tipos de formularios",
      key: "label",
    },
    forms: {
      label: "Formularios",
      key: "label",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados das campos de formularios.",
  erroLoadTrash: "Erro ao carregar dados das campos de formularios  para lixeira.",
  itemRemovidoLixeira: "Disciolina movida pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover campos de formularios para a lixeira.",
  itemExcuidoLixeira: "Campos de formularios excluída da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir campos de formularios da lixeira.",
  itemRestauradoLixeira: "Campos de formularios restaurada com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar campos de formularios.",
  createTitle: "Novo campo de formulario",
  create: "Campos de formularios criada com sucesso.",
  update: "Campos de formularios editada com sucesso.",
  updateTitle: "Editar campos de formularios",
  createError: "Erro ao criar a campos de formularios.",
  updateError: "Erro ao editar a campos de formularios.",
  inputs: {
    id: null,
    id_type: null,
    id_form: null,
    label: null,
    class: null,
    order: null,
    description: null,
    placeholder: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da campos de formularios",
      example: "1",
    },
    id_type: {
      label: "Tipo de formulário",
      description: "Tipo de formulário",
      example: "1",
    },
    id_form: {
      label: "Formulário",
      description: "Formulário",
      example: "1",
    },
    label: {
      label: "Nome da campos de formularios",
      description: "Nome da campos de formularios",
      example: "Inscrição",
    },
    class: {
      label: "Classe",
      description: "Classe relacionada",
      example: "Classe 2",
    },
    order: {
      label: "Ordem",
      description: "Ordem do campo de formulário",
      example: "1",
    },
    placeholder: {
      label: "Placeholder (valor de exemplo)",
      description: "Placeholder (valor de exemplo) do campo",
      example: "Exemplo de valor",
    },
    description: {
      label: "Descrição",
      description: "Descrição do campo",
      example: "Nome do campo tal",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da campos de formularios",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da campos de formularios",
      example: "2021-01-01 00:00:00",
    }
  }
}
