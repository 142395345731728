const labels = {
  title: 'Produto',
  plural: 'Produtos',
  upper: 'PRODUTO',
  upperPlural: 'PRODUTOS',
  lower: 'produto',
  lowerPlural: 'produtos'
}

export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {
    id: {
      label: "ID do produto",
      key: "id",
    },
    name: {
      label: "Nome do produto",
      key: "name",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    value: {
      label: "Valor",
      key: "value",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    pay_products_types: {
      label: "Tipo de produto",
      key: "label",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados dos produtos.",
  erroLoadTrash: "Erro ao carregar dados dos produtos  para lixeira.",
  itemRemovidoLixeira: "Produto movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover produto para a lixeira.",
  itemExcuidoLixeira: "Produto excluída da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir produto da lixeira, existem dados vinculados a esse registro, remova os dados para excluir o registro.",
  itemRestauradoLixeira: "Produto restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar produto.",
  createTitle: "Novo produto",
  create: "Produto criado com sucesso.",
  update: "Produto editado com sucesso.",
  updateTitle: "Editar produto",
  createError: "Erro ao criar o produto.",
  updateError: "Erro ao editar o produto.",
  inputs: {id: null, name: "", description: "", value: "", id_pay_product_type: ""},
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID do Produto",
      example: "1",
    },
    name: {
      label: "Nome do Produto",
      description: "Nome do Produto",
      example: "Produto",
    },
    description: {
      label: "Descrição",
      description: "Descrição do Produto",
      example: "Novo Produto",
    },
    value: {
      label: "Valor",
      description: "Valor do Produto",
      example: "9.99",
    },
    id_pay_product_type: {
      label: "Tipo de produto",
      description: "Tipo de produto",
      example: "Produto 1",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação do Produto",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização do Produto",
      example: "2021-01-01 00:00:00",
    }
  }
}
