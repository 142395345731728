export default {
  title: 'Trilha de classe',
  plural: 'Trilhas de classes',
  upper: 'TRILHA DE CLASSE',
  upperPlural: 'TRILHAS DE CLASSES',
  lower: 'trilha de classe',
  lowerPlural: 'trilhas de classes',
  headers: [
    "ID",
    "Data de criação",
    "Última Atualização",
    "Nome",
    "Identificador",
    "Sigla",
    "Nome usual",
  ],
  erroLoadPaginate: "Erro ao carregar dados de disciplinas.",
  erroLoadTrash: "Erro ao carregar dados da lixeira.",
  itemRemovidoLixeira: "Item movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover o item",
  itemExcuidoLixeira: "Item excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir o item da lixeira.",
  itemRestauradoLixeira: "Item restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar o item.",
  createTitle: "Nova disciplina",
  create: "ClassesTrails criada com sucesso.",
  update: "ClassesTrails editada com sucesso.",
  updateTitle: "Editar disciplina",
  createError: "Erro ao criar a disciplina.",
  updateError: "Erro ao editar a disciplina.",
  inputs: {id: null, label: "", identifier: "", acronym: "", usual_name: ""},
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da disciplina",
      example: "1",
    },
    label: {
      label: "Nome da disciplina",
      description: "Nome da disciplina",
      example: "Matemática",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador da disciplina",
      example: "matematica",
    },
    acronym: {
      label: "Sigla",
      description: "Sigla da disciplina",
      example: "MAT",
    },
    usual_name: {
      label: "Nome usual",
      description: "Nome usual da disciplina",
      example: "Matemática",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da disciplina",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da disciplina",
      example: "2021-01-01 00:00:00",
    }
  }
}
