import {curriculumsCreate, curriculumsDeletex, curriculumsFetch, curriculumsFind, curriculumsPaginate, curriculumsRemove, curriculumsRestore, curriculumsTrash, curriculumsUpdate} from "@/modules/curriculums/curriculums-service";

const state = {
  curriculums: [],
  paginate: {},
  load: null,
  item: {},
};
const idSchool = 1;
const getters = {
  getCurriculums(state) {
    const result = state.curriculums.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getItem(state){
    return state.item;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_CURRICULUMS(state, curriculums) {
    state.curriculums = curriculums;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_ITEM(state, item) {
    state.item = item;
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getCurriculums({commit}) {
    try {
      const response = await curriculumsFetch(idSchool);
      const {data} = response;
      commit("SET_CURRICULUMS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async curriculumsFind({commit, dispatch}, payload) {
    try {
      //console.log('curriculumsCreate',payload);
      const response = await curriculumsFind(idSchool, payload);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  async curriculumsCreate({commit, dispatch}, payload) {
    try {
      //console.log('curriculumsCreate',payload);
      const response = await curriculumsCreate(idSchool, payload);
      dispatch("getCurriculums", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async curriculumsUpdate({commit, dispatch}, payload) {
    try {
      const response = await curriculumsUpdate(idSchool, payload);
      dispatch("getCurriculums", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async curriculumsUpdateV2({commit, dispatch}, payload) {
    try {
      const response = await curriculumsUpdate(idSchool, payload);
      commit("SET_ITEM", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async curriculumsDeletex({commit}, query) {
    try {
      const response = await curriculumsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async curriculumsPaginate({commit}, query) {
    try {
      const response = await curriculumsPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async curriculumsRemove({commit}, payload) {
    try {
      const response = await curriculumsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async curriculumsTrash({commit}, query) {
    try {
      const response = await curriculumsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async curriculumsRestore({commit}, payload) {
    try {
      const response = await curriculumsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
