import labels from "@/modules/cards-files/cards-files-labels";
export default {
    path: "/cards-files",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./cards-files.vue")
}

