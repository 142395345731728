import Axios from "axios";

const domain = process.env.VUE_APP_DOMAIN;
const name = "cards_comments";

const axiosInstance = Axios.create({
    baseURL: domain + "/v1_atendimento",
});

export const cardsCommentsFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const cardsCommentsCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/${name}/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const cardsCommentsUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${name}/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const cardsCommentsDeletex = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/${name}/delete/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const cardsCommentsPaginate = async (id_school, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;

        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/paginate`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const cardsCommentsRemove = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/${name}/remove/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const cardsCommentsTrash = async (id_school, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/trash`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const cardsCommentsRestore = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${name}/restore/${payload.id}`,
            data: {id: payload.id},
        });
        return response;
    } catch (error) {
        return error;
    }
};
