import labels from "@/modules/periodic-models/periodic-models-labels";
export default {
    path: "/periodic-models",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./periodic-models.vue")
}

