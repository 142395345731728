import axios from "axios";

const url =
    `${process.env.VUE_APP_DOMAIN}/v2_post/v1/1/taxonomies`;

export const fetchTaxonomies = async (query) => {


    try {
        const {data} = await axios({
            baseURL: url,
            method: "get",
            url: `/paginate`,
            params: query
        });

        for (const item of data.data) {
            item.image_view = item.image;
            item.video_view = item.video;
            item.audio_view = item.audio;
        }

        return data;
    } catch (error) {
        return error;
    }
};


export const fetchAllTaxonomies = async (query) => {
    try {
        const {data} = await axios({
            baseURL: url,
            method: "get",
            url: "",
            params: query,
        });

        return data;
    } catch (error) {
        return error;
    }
}

export const findTaxonomiesType = async (id) => {
    try {
        const {data} = await axios({
            method: "get",
            url: `${url}_types/${id}`,
        });
        return data;
    } catch (error) {
        return error;
    }
}

export const createTaxonomies = async (payload) => {


    try {
        const response = await axios({
            baseURL: url,
            method: "post",
            data: payload,
            url: "/create",
        });

        return response;
    } catch (error) {

        return error;
    }
};

export const uploadFiles = async (file, id, key) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", id);
    formData.append("key", key);

    try {
        await axios(`${url}/uploads`, formData);
    } catch (error) {
        return error;
    }
};

export const updateTaxonomies = async (id,payload) => {


    try {
        const response = await axios({
            baseURL: url,
            method: "put",
            data: payload,
            url: `/update/${id}`,
        });

        return response;
    } catch (error) {
        return error;
    }
};

export const deleteTaxonomies = async (payload) => {
    try {
        const response = await axios({
            method: "delete",
            url: `${url}/delete`,
            data: {...payload},
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const removeTaxonomies = async (payload) => {
    try {
        const response = await axios({
            method: "put",
            url: `${url}/remove`,
            data: {...payload},
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const getTrash = async ({
                                   search = "",
                                   page = 1,
                                   perPage = 10,
                                   id_taxonomy_type,
                               }) => {
    const queryString = search !== "" ? `?search=${search}&` : "?";

    try {
        const {data} = await axios({
            baseURL: url,
            method: "get",
            url: `/type/trash/${id_taxonomy_type}${queryString}page=${page}&per_page=${perPage}`,
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const restore = async (payload) => {
    try {
        const response = await axios({
            method: "put",
            url: `${url}/restore`,
            data: {...payload},
        });
        return response;
    } catch (error) {
        return error;
    }
};
