import labels from "@/modules/pay-invoices-documents-fiscal/pay-invoices-documents-fiscal-labels";
export default {
    path: "/pay-invoices-documents-fiscal",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./pay-invoices-documents-fiscal.vue")
}

