import Axios from "axios";

// const user = JSON.parse(localStorage.getItem("auth.currentUser"));

const REQUEST =
  `${process.env.VUE_APP_DOMAIN}/v1_post/v1/1/config`;

const axiosInstance = Axios.create({
  //   headers: { Authorization: user.stsTokenManager.accessToken },
  baseURL: REQUEST,
});

export const fetchBranchSchoolsConfigurations = async ({
  search = "",
  page = 1,
  perPage = 10,
}) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/paginate${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchTrash = async ({ search = "", page = 1, perPage = 10 }) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/trash/paginate${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const saveBranchSchoolsConfigurations = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: payload.id ? "put" : "post",
      data: {
        id_user: 1,
        ...payload,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const removeBranchSchoolsConfigurations = async (id) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `/remove`,
      data: {
        id: id,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const restoreBranchSchoolsConfigurations = async (id) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `/restore`,
      data: {
        id: id,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteBranchSchoolsConfigurations = async (id) => {
  try {
    const { data } = await axiosInstance({
      method: "delete",
      url: `/delete`,
      data: {
        id: id,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};
