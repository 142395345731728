import {cardsRolesCreate, cardsRolesDeletex, cardsRolesFetch, cardsRolesPaginate, cardsRolesRemove, cardsRolesRestore, cardsRolesTrash, cardsRolesUpdate} from "@/modules/cards-roles/cards-roles-service";

const state = {
  cardsRoles: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getCardsRoles(state) {
    const result = state.cardsRoles.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_CARDS_ROLES(state, cardsRoles) {
    state.cardsRoles = cardsRoles;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getCardsRoles({commit}) {
    try {
      const response = await cardsRolesFetch(idSchool);
      const {data} = response;
      commit("SET_CARDS_ROLES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsRolesCreate({commit, dispatch}, payload) {
    try {
      //console.log('cardsRolesCreate',payload);
      const response = await cardsRolesCreate(idSchool, payload);
      dispatch("getCardsRoles", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsRolesUpdate({commit, dispatch}, payload) {
    try {
      const response = await cardsRolesUpdate(idSchool, payload);
      dispatch("getCardsRoles", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsRolesDeletex({commit}, query) {
    try {
      const response = await cardsRolesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsRolesPaginate({commit}, query) {
    try {
      const response = await cardsRolesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async cardsRolesRemove({commit}, payload) {
    try {
      const response = await cardsRolesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async cardsRolesTrash({commit}, query) {
    try {
      const response = await cardsRolesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async cardsRolesRestore({commit}, payload) {
    try {
      const response = await cardsRolesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
