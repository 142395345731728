import Axios from "axios";

const domain = process.env.VUE_APP_DOMAIN;

const axiosInstance = Axios.create({
    baseURL: domain,
});

const address = "/v3_peoples";
const locations = "/v1_locations";

export const statesFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${locations}/v1/${id_school}/state`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const citiesFetch = async (id_school, id_state) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${locations}/v1/${id_school}/citie?id_state=${id_state}`,
        });
        return response;
    } catch (error) {
        return error;
    }

}

export const peoplesAddressFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            params: payload,
            url: `${address}/v1/${id_school}/peoples_address`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const peoplesAddressCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `${address}/v1/${id_school}/peoples_address/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const peoplesAddressUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `${address}/v1/${id_school}/peoples_address/update/${payload.id}`,
            data: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};


export const peoplesAddressPaginate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_address/paginate`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const peoplesAddressRemove = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `${address}/v1/${id_school}/peoples_address/remove/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const peoplesAddressDelete = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `${address}/v1/${id_school}/peoples_address/delete/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const peoplesAddressTrash = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_address/trash`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const peoplesAddressRestore = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `${address}/v1/${id_school}/peoples_address/restore/${payload.id}`,
            data: {id: payload.id},
        });
        return response;
    } catch (error) {
        return error;
    }
};


export const viaCep = async (cep) => {
    try {
        return axiosInstance({
            method: "get",
            url: `https://viacep.com.br/ws/${cep}/json/`,
        });
    } catch (error) {
        return error;
    }
};

//peoples_telephones_types
export const peoplesTelephonesTypesFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_telephones_types`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

//peoples_telephones
export const peoplesTelephonesFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_telephones`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

//save

export const peoplesTelephonesCreate = async (id_school, payload) => {
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_telephones/create`,
        data: payload,
    });
}

//update

export const peoplesTelephonesUpdate = async (id_school, payload) => {
    return axiosInstance({
        method: "put",
        url: `${address}/v1/${id_school}/peoples_telephones/update/${payload.id}`,
        data: payload,
    });
}

//remove

export const peoplesTelephonesRemove = async (id_school, payload) => {
    return axiosInstance({
        method: "delete",
        url: `${address}/v1/${id_school}/peoples_telephones/remove/${payload.id}`,
    });
}


//peoples_responsible_types
export const peoplesResponsibleTypesFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_responsible_types`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

//peoples_responsible

export const peoplesResponsibleFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_responsible`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//save

export const peoplesResponsibleCreate = async (id_school, payload) => {
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_responsible/create`,
        data: payload,
    });

}

//update

export const peoplesResponsibleUpdate = async (id_school, payload) => {
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_responsible/update/${payload.id}`,
        data: payload,
    });

}

//remove

export const peoplesResponsibleRemove = async (id_school, payload) => {
    return axiosInstance({
        method: "delete",
        url: `${address}/v1/${id_school}/peoples_responsible/remove/${payload.id}`,
    });
}


//peoples_specialties_types

export const peoplesSpecialtiesTypesFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_specialties_types`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//peoplesSpecialtiesFetch

export const peoplesSpecialtiesFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_specialties`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }

}

//save

export const peoplesSpecialtiesCreate = async (id_school, payload) => {
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_specialties/create`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

//update

export const peoplesSpecialtiesUpdate = async (id_school, payload) => {
    const id = payload.get('id')
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_specialties/update/${id}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

//remove

export const peoplesSpecialtiesRemove = async (id_school, payload) => {
    return axiosInstance({
        method: "delete",
        url: `${address}/v1/${id_school}/peoples_specialties/remove/${payload.id}`,
    });
}


//peoples_documents_types

export const peoplesDocumentsTypesFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_documents_types`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//peoples_documents

export const peoplesDocumentsFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_documents`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//save

export const peoplesDocumentsCreate = async (id_school, payload) => {
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_documents/create`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

//update

export const peoplesDocumentsUpdate = async (id_school, payload) => {
    const id = payload.get('id')
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_documents/update/${id}`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

//remove

export const peoplesDocumentsRemove = async (id_school, payload) => {
    return axiosInstance({
        method: "delete",
        url: `${address}/v1/${id_school}/peoples_documents/remove/${payload.id}`,
    });
}

//notes_types

export const notesTypesFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/notes_types`,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//notes

export const notesFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/notes`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//save
export const notesCreate = async (id_school, payload) => {

    //multipart/form-data
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/notes/create`,
        data: payload,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

//update

export const notesUpdate = async (id_school, payload) => {
    const id = payload.get('id')
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/notes/update/${id}`,
        data: payload,
    });
}

//remove

export const notesRemove = async (id_school, payload) => {
    return axiosInstance({
        method: "delete",
        url: `${address}/v1/${id_school}/notes/delete/${payload.id}`,
    });
}


//peoples_defaults

export const peoplesDefaultsFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_defaults`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//save

export const peoplesDefaultsCreate = async (id_school, payload) => {
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_defaults/create`,
        data: payload,
    });
}

//update

export const peoplesDefaultsUpdate = async (id_school, payload) => {
    return axiosInstance({
        method: "put",
        url: `${address}/v1/${id_school}/peoples_defaults/update/${payload.id}`,
        data: payload,
    });
}

//peoples_extras_types

export const peoplesExtrasTypesFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_extras_types`,
        });
        return response;
    } catch (error) {
        return error;
    }
}


//peoples_extras

export const peoplesExtrasFetch = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `${address}/v1/${id_school}/peoples_extras`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
}

//save

export const peoplesExtrasCreate = async (id_school, payload) => {
    return axiosInstance({
        method: "post",
        url: `${address}/v1/${id_school}/peoples_extras/create`,
        data: payload,
    });
}

//update

export const peoplesExtrasUpdate = async (id_school, payload) => {
    return axiosInstance({
        method: "put",
        url: `${address}/v1/${id_school}/peoples_extras/update/${payload.id}`,
        data: payload,
    });
}

//remove

export const peoplesExtrasRemove = async (id_school, payload) => {
    return axiosInstance({
        method: "delete",
        url: `${address}/v1/${id_school}/peoples_extras/remove/${payload.id}`,
    });
}