import labels from "@/modules/cards-logs/cards-logs-labels";
export default {
    path: "/cards-logs",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./cards-logs.vue")
}

