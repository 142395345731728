import {postsConfigsCreate, postsConfigsDeletex, postsConfigsFetch, postsConfigsPaginate, postsConfigsRemove, postsConfigsRestore, postsConfigsTrash, postsConfigsUpdate, postsTypesFetch, taxonomiesTypesFetch}
    from "@/modules/posts-configs/posts-configs-service";

const state = {
    postsConfigs: [],
    paginate: {},
    load: null,
    taxonomies: [],
    postTypes: [],
};
const idSchool = 1;
const getters = {
    getPostsConfigs(state) {
        const result = state.postsConfigs.map((item) => ({
            ...item,
            showEdit: false,
        }));

        return result;
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
    getTaxonomies(state) {
        return state.taxonomies;
    },
    getPostTypes(state) {
        return state.postTypes;
    },
};

const mutations = {
    SET_POSTS_CONFIGS(state, postsConfigs) {
        state.postsConfigs = postsConfigs;
    },
    SET_TAXONOMIES(state, taxonomies) {
        state.taxonomies = taxonomies;
    },
    SET_POST_TYPES(state, postTypes) {
        state.postTypes = postTypes
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getPostsConfigs({commit}) {
        try {
            const response = await postsConfigsFetch(idSchool);
            const {data} = response;
            commit("SET_POSTS_CONFIGS", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async postsConfigsCreate({commit, dispatch}, payload) {
        try {
            //console.log('postsConfigsCreate',payload);
            const response = await postsConfigsCreate(idSchool, payload);
            dispatch("getPostsConfigs", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async postsConfigsUpdate({commit, dispatch}, payload) {
        try {
            const response = await postsConfigsUpdate(idSchool, payload);
            dispatch("getPostsConfigs", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async postsConfigsDeletex({commit}, query) {
        try {
            const response = await postsConfigsDeletex(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async postsConfigsPaginate({commit}, query) {
        try {
            const response = await postsConfigsPaginate(idSchool, query);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async postsConfigsRemove({commit}, payload) {
        try {
            const response = await postsConfigsRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async postsConfigsTrash({commit}, query) {
        try {
            const response = await postsConfigsTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async postsConfigsRestore({commit}, payload) {
        try {
            const response = await postsConfigsRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    //taxonomies_types
    async taxonomiesTypes({commit}, payload) {
        try {
            const response = await taxonomiesTypesFetch(idSchool);
            console.log('taxonomiesTypes',response.data);
            commit("SET_TAXONOMIES", response.data);
        } catch (error) {
            console.log(error);
        }
    },
    //posts_types
    async postTypes({commit}, payload) {
        try {
            const response = await postsTypesFetch(idSchool);
            console.log('postTypes',response.data);
            commit("SET_POST_TYPES", response.data);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
