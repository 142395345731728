import {
  saveBranchSchoolsConfigurations as saveBranchSchoolsConfigurationsService,
  fetchBranchSchoolsConfigurations,
  deleteBranchSchoolsConfigurations as deleteBranchSchoolsConfigurationsService,
  restoreBranchSchoolsConfigurations,
  removeBranchSchoolsConfigurations,
  fetchTrash,
} from "../services/services";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  branchSchoolsConfigurations: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  branchSchoolsConfigurations(state) {
    return state.branchSchoolsConfigurations;
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_BRANCH_SCHOOLS_CONFIGURATIONS(state, payload) {
    state.branchSchoolsConfigurations = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading= payload;
  },

  SET_BRANCH_SCHOOLS_CONFIGURATIONS_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },
};

const actions = {
  async getBranchSchoolsConfigurations({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchBranchSchoolsConfigurations(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_BRANCH_SCHOOLS_CONFIGURATIONS", response.data);
        commit(
          "SET_BRANCH_SCHOOLS_CONFIGURATIONS_PAGINATION",
          response.pagination
        );
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_BRANCH_SCHOOLS_CONFIGURATIONS", response.data);
        commit(
          "SET_BRANCH_SCHOOLS_CONFIGURATIONS_PAGINATION",
          response.pagination
        );
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveBranchSchoolsConfigurationsService(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getBranchSchoolsConfigurations");
        this.getBranchSchoolsConfigurations();
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removeBranchSchoolsConfigurations(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getPosts");
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restoreBranchSchoolsConfigurations(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getPosts");
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteBranchSchoolsConfigurationsService(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getBranchSchoolsConfigurations");
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
