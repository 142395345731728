import {
  fetchTrash,
  saveResponsiblesPeoples,
  fetchResponsiblesPeoples,
  removeResponsiblesPeoples,
  deleteResponsiblesPeoples,
  restoreResponsiblesPeoples,
} from "../services/services";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  responsiblesPeoples: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  responsiblesPeoples(state) {
    return state.responsiblesPeoples;
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_RESPONSIBLES_PEOPLES(state, payload) {
    state.responsiblesPeoples = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_RESPONSIBLES_PEOPLES_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },
};

const actions = {
  async getResponsiblesPeoples({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchResponsiblesPeoples(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_RESPONSIBLES_PEOPLES", response.data);
        commit("SET_RESPONSIBLES_PEOPLES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_RESPONSIBLES_PEOPLES", response.data);
        commit("SET_RESPONSIBLES_PEOPLES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch, getters }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveResponsiblesPeoples(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getResponsiblesPeoples", {});
        else dispatch("getTrash", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch, getters }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removeResponsiblesPeoples(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getResponsiblesPeoples", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch, getters }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restoreResponsiblesPeoples(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getResponsiblesPeoples", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteResponsiblesPeoples(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getResponsiblesPeoples", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
