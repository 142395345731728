import labels from "@/modules/permissions/permissions-labels";
export default {
    path: "/permissions",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./permissions.vue")
}

