import {calendarsTypesCreate, calendarsTypesDeletex, calendarsTypesFetch, calendarsTypesPaginate, calendarsTypesRemove, calendarsTypesRestore, calendarsTypesTrash, calendarsTypesUpdate} from "@/modules/calendars-types/calendars-types-service";

const state = {
  calendarsTypes: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getCalendarsTypes(state) {
    try{
      //console.log('getCalendarsTypes',state.calendarsTypes);
      const result = state.calendarsTypes.data.map((item) => ({
        ...item,
        showEdit: false,
      }));

      return result;
    }catch (e) {
      return [];
    }
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_CALENDARS_TYPES(state, calendarsTypes) {
    state.calendarsTypes = calendarsTypes;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getCalendarsTypes({commit}) {
    try {
      const response = await calendarsTypesFetch(idSchool);
      const {data} = response;
      commit("SET_CALENDARS_TYPES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async calendarsTypesCreate({commit, dispatch}, payload) {
    try {
      //console.log('calendarsTypesCreate',payload);
      const response = await calendarsTypesCreate(idSchool, payload);
      dispatch("getCalendarsTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async calendarsTypesUpdate({commit, dispatch}, payload) {
    try {
      const response = await calendarsTypesUpdate(idSchool, payload);
      dispatch("getCalendarsTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async calendarsTypesDeletex({commit}, query) {
    try {
      const response = await calendarsTypesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async calendarsTypesPaginate({commit}, query) {
    try {
      const response = await calendarsTypesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async calendarsTypesRemove({commit}, payload) {
    try {
      const response = await calendarsTypesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async calendarsTypesTrash({commit}, query) {
    try {
      const response = await calendarsTypesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async calendarsTypesRestore({commit}, payload) {
    try {
      const response = await calendarsTypesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
