import Axios from "axios";

const REQUEST =
    `${process.env.VUE_APP_DOMAIN}/v1_peoples/v1/1/people`;

const axiosInstance = Axios.create({
    baseURL: REQUEST,
});

const axiosInstanceTwo = Axios.create({
    baseURL: `${process.env.VUE_APP_DOMAIN}/v3_peoples/v1/1/`,
});

export const fetchAllPeoples = async () => {
    try {
        const {data} = await axiosInstance.get("");

        return data;
    } catch (error) {
        return error;
    }
};

export const fetchPeoples = async ({
                                       search = "",
                                       page = 1,
                                       perPage = 10,
                                   }) => {
    const queryString = search !== "" ? `?search=${search}&` : "?";

    try {
        const {data} = await axiosInstance({
            method: "get",
            url: `paginate${queryString}page=${page}&per_page=${perPage}`,
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const fetchTrash = async ({search = "", page = 1, perPage = 10}) => {
    const queryString = search !== "" ? `?search=${search}&` : "?";

    try {
        const {data} = await axiosInstance({
            method: "get",
            url: `/trash/paginate${queryString}page=${page}&per_page=${perPage}`,
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const savePeoples = async (payload) => {
    try {
        const {data} = await axiosInstance({
            method: payload.id ? "put" : "post",
            data: {...payload},
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const saveTwo = async (id, payload) => {
    try {
        const {data} = await axiosInstanceTwo({
            method: "post",
            url: parseInt(id) > 0 ? `peoples/update/${id}` : `peoples/create`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: payload,
        });
        return data;
    } catch (error) {
        return error;
    }
}

export const allTwo = async () => {
    try {
        const {data} = await axiosInstanceTwo({
            method: "get",
            url: `peoples`,
        });

        return data;
    } catch (error) {
        return error;
    }
}


export const removePeoples = async (payload) => {
    try {
        const {data} = await axiosInstance({
            method: "put",
            url: `/remove`,
            data: {...payload},
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const restorePeoples = async (id) => {
    try {
        const {data} = await axiosInstance({
            method: "put",
            url: `/restore`,
            data: {
                id: id,
            },
        });

        return data;
    } catch (error) {
        return error;
    }
};

export const deletePeoples = async (payload) => {
    try {
        const {data} = await axiosInstance({
            method: "delete",
            url: `/delete`,
            data: {...payload},
        });

        return data;
    } catch (error) {
        return error;
    }
};
