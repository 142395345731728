import {physicalRoomsTypesCreate, physicalRoomsTypesDeletex, physicalRoomsTypesFetch, physicalRoomsTypesPaginate, physicalRoomsTypesRemove, physicalRoomsTypesRestore, physicalRoomsTypesTrash, physicalRoomsTypesUpdate} from "@/modules/physical-rooms-types/physical-rooms-types-service";

const state = {
  physicalRoomsTypes: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getPhysicalRoomsTypes(state) {
    const result = state.physicalRoomsTypes.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_PHYSICAL_ROOMS_TYPES(state, physicalRoomsTypes) {
    state.physicalRoomsTypes = physicalRoomsTypes;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getPhysicalRoomsTypes({commit}) {
    try {
      const response = await physicalRoomsTypesFetch(idSchool);
      const {data} = response;
      commit("SET_PHYSICAL_ROOMS_TYPES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async physicalRoomsTypesCreate({commit, dispatch}, payload) {
    try {
      //console.log('physicalRoomsTypesCreate',payload);
      const response = await physicalRoomsTypesCreate(idSchool, payload);
      dispatch("getPhysicalRoomsTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async physicalRoomsTypesUpdate({commit, dispatch}, payload) {
    try {
      const response = await physicalRoomsTypesUpdate(idSchool, payload);
      dispatch("getPhysicalRoomsTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async physicalRoomsTypesDeletex({commit}, query) {
    try {
      const response = await physicalRoomsTypesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async physicalRoomsTypesPaginate({commit}, query) {
    try {
      const response = await physicalRoomsTypesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async physicalRoomsTypesRemove({commit}, payload) {
    try {
      const response = await physicalRoomsTypesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async physicalRoomsTypesTrash({commit}, query) {
    try {
      const response = await physicalRoomsTypesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async physicalRoomsTypesRestore({commit}, payload) {
    try {
      const response = await physicalRoomsTypesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
