import {daysOfWeeksCreate, daysOfWeeksDeletex, daysOfWeeksFetch, daysOfWeeksPaginate, daysOfWeeksRemove, daysOfWeeksRestore, daysOfWeeksTrash, daysOfWeeksUpdate} from "@/modules/days-of-weeks/days-of-weeks-service";

const state = {
  daysOfWeeks: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getDaysOfWeeks(state) {
    const result = state.daysOfWeeks.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_DAYS_OF_WEEKS(state, daysOfWeeks) {
    state.daysOfWeeks = daysOfWeeks;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getDaysOfWeeks({commit}) {
    try {
      const response = await daysOfWeeksFetch(idSchool);
      const {data} = response;
      commit("SET_DAYS_OF_WEEKS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async daysOfWeeksCreate({commit, dispatch}, payload) {
    try {
      //console.log('daysOfWeeksCreate',payload);
      const response = await daysOfWeeksCreate(idSchool, payload);
      dispatch("getDaysOfWeeks", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async daysOfWeeksUpdate({commit, dispatch}, payload) {
    try {
      const response = await daysOfWeeksUpdate(idSchool, payload);
      dispatch("getDaysOfWeeks", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async daysOfWeeksDeletex({commit}, query) {
    try {
      const response = await daysOfWeeksDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async daysOfWeeksPaginate({commit}, query) {
    try {
      const response = await daysOfWeeksPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async daysOfWeeksRemove({commit}, payload) {
    try {
      const response = await daysOfWeeksRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async daysOfWeeksTrash({commit}, query) {
    try {
      const response = await daysOfWeeksTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async daysOfWeeksRestore({commit}, payload) {
    try {
      const response = await daysOfWeeksRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
