import {policiesCreate, policiesDeletex, policiesFetch, policiesPaginate, policiesRemove, policiesRestore, policiesTrash, policiesUpdate} from "@/modules/policies/policies-service";

const state = {
  policies: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getAll(){
    return state.policies;
  },
  getPolicies(state) {
    const result = state.policies.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_POLICIES(state, policies) {
    state.policies = policies;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getPolicies({commit}) {
    try {
      const response = await policiesFetch(idSchool);
      const {data} = response;
      commit("SET_POLICIES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async policiesCreate({commit, dispatch}, payload) {
    try {
      //console.log('policiesCreate',payload);
      const response = await policiesCreate(idSchool, payload);
      dispatch("getPolicies", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async policiesUpdate({commit, dispatch}, payload) {
    try {
      const response = await policiesUpdate(idSchool, payload);
      dispatch("getPolicies", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async policiesDeletex({commit}, query) {
    try {
      const response = await policiesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async policiesPaginate({commit}, query) {
    try {
      const response = await policiesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async policiesRemove({commit}, payload) {
    try {
      const response = await policiesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async policiesTrash({commit}, query) {
    try {
      const response = await policiesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async policiesRestore({commit}, payload) {
    try {
      const response = await policiesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
