import labels from "@/modules/physical-rooms/physical-rooms-labels";
export default {
    path: "/physical-rooms",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./physical-rooms.vue")
}

