const labels = {
    title: 'Calendário',
    plural: 'Calendários',
    upper: 'CALENDÁRIO',
    upperPlural: 'CALENDÁRIOS',
    lower: 'calendário',
    lowerPlural: 'calendários'
}
export default {
    title: labels.title,
    plural: labels.plural,
    upper: labels.upper,
    upperPlural: labels.upperPlural,
    lower: labels.lower,
    lowerPlural: labels.lowerPlural,
    headers: {

        id: {
            label: "ID",
            key: "id",
        },
        title: {
            label: "Título do evento",
            key: "title",
        },
        description: {
            label: "Descrição",
            key: "description",
        },
        status: {
            label: "Status",
            key: "status",
        },
        is_important: {
            label: "Importante?",
            key: "is_important",
        },
        is_not_weekend: {
            label: "Não é final de semana?",
            key: "is_not_weekend",
        },
        created_at: {
            label: "Data de criação",
            key: "created_at",
        },
        updated_at: {
            label: "Última atualização",
            key: "updated_at",
        },
        calendars_types: {
            label: "Tipo",
            key: "label",
        },
    },
    erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
    erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
    itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
    itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
    itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
    itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
    itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
    itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
    createTitle: `Novo ${labels.lower}`,
    create: `${labels.title} criado com sucesso.`,
    update: `${labels.title} editado com sucesso.`,
    updateTitle: `Editar ${labels.lower} disciplina`,
    createError: `Erro ao criar o ${labels.lower}.`,
    updateError: `Erro ao editar a ${labels.lower}.`,
    inputs: {
        id: null,
        title: "",
        description: "",
        status: 1,
        is_important: 0,
        is_not_weekend: 0,
        id_calendars_type: null,
        calendars_times: []
    },
    inputsInfos: {
        id: {
            label: "ID",
            description: `ID do ${labels.lower}`,
            example: "1",
        },
        title: {
            label: "Título do evento",
            description: `Título do ${labels.lower}`,
            example: "Título do evento",
        },
        description: {
            label: "Descrição",
            description: `Descrição do ${labels.lower}`,
            example: "Descrição do evento",
        },
        status: {
            label: "Status",
            description: `Status do ${labels.lower}`,
            example: "1",
        },
        is_important: {
            label: "Importante?",
            description: `O evento é importante?`,
        },
        is_not_weekend: {
            label: "Exibir aos fins de semana?",
            description: `O evento  não é final de semana?`,
        },
        id_calendars_type: {
            label: "Tipo",
            description: `Tipo do ${labels.lower}`,
            example: "1",
        },
        created_at: {
            label: "Data de criação",
            description: `Data de criação do ${labels.lower}`,
            example: "2021-01-01 00:00:00",
        },
        updated_at: {
            label: "Última atualização",
            description: `Data da última atualização do ${labels.lower}`,
            example: "2021-01-01 00:00:00",
        }
    }
}
