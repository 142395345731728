import labels from "@/modules/classes/classes-labels";
export default {
    path: "/classes/:id",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./classesView.vue")
}

