export default {
  title: 'Recurso para sala física',
  plural: 'Recursos para sala física',
  upper: 'RECURSO PARA SALA FÍSICA',
  upperPlural: 'RECURSOS PARA SALA FÍSICA',
  lower: 'recurso para sala física',
  lowerPlural: 'recursos para sala física',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    label: {
      label: "Nome do recurso para sala física",
      key: "label",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },

  erroLoadPaginate: "Erro ao carregar dados recursos para salas físicas.",
  erroLoadTrash: "Erro ao carregar recursos para salas físicas da lixeira.",
  itemRemovidoLixeira: "Recurso para sala física movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover o recurso para sala física para a lixeira.",
  itemExcuidoLixeira: "recurso para sala física excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir recurso para sala física, existem itens vinculados a esse registro, exemplo: Salas físicas.",
  itemRestauradoLixeira: "Recurso para sala física restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar recurso para sala física.",
  createTitle: "Novo recurso para sala física",
  create: "Recurso para sala física criada com sucesso.",
  update: "Recurso para sala física editada com sucesso.",
  updateTitle: "Editar recurso para sala física",
  createError: "Erro ao criar recurso para sala física.",
  updateError: "Erro ao editar recurso para sala física.",
  inputs: {id: null, label: "", identifier: "" },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da recurso para sala física",
      example: "1",
    },
    label: {
      label: "Nome do recurso para sala física",
      description: "Nome do recurso para sala física",
      example: "Matemática",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador do recurso para sala física",
      example: "matematica",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação do recurso para sala física",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização do recurso para sala física",
      example: "2021-01-01 00:00:00",
    }
  }
}
