const labels = {
  title: 'Feriado',
  plural: 'Feriados',
  upper: 'FERIADO',
  upperPlural: 'FERIADOS',
  lower: 'feriado',
  lowerPlural: 'feriados'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {


    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome",
      key: "label",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    months: {
      label: "Mês",
      key: "label",
    },
    years: {
      label: "Ano",
      key: "year",
    },
    days_of_weeks: {
      label: "Dias da semana",
      key: "label",
    },
    weeks: {
      label: "Semana",
      key: "weeks",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    label: null,
    description: null,
    id_week: null,
    id_year: null,
    id_day_of_week: null,
    id_month: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    label: {
      label: `Nome do ${labels.lower}`,
      description: `Nome do ${labels.lower}`,
      example: "Exemplo: Finados",
    },
    description: {
      label: "Descrição",
      description: `Descrição do ${labels.lower}`,
      example: "Exemplo: finados",
    },
    id_week: {
      label: "Semana",
      description: `Semana do ${labels.lower}`,
      example: "",
    },
    id_year: {
      label: "ANO",
      description: `ANO do ${labels.lower}`,
      example: "",
    },
    id_day_of_week: {
      label: "Dia da semana",
      description: `Dia da semana do ${labels.lower}`,
      example: "",
    },
    id_month: {
      label: "Mês",
      description: `Mês do ${labels.lower}`,
      example: "",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
