import {
  deleteStates,
  fetchStates,
  fetchTrash,
  removeStates,
  restoreStates,
  saveStates,
} from "../services/services.js";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  states: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  states(state) {
    return state.states;
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_STATES(state, payload) {
    state.states = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_STATES_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },

  SET_STATES_OPTIONS(state, payload) {
    state.paymentsOptions = payload;
  },
};

const actions = {
  async getStates({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchStates(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_STATES", response.data);
        commit("SET_STATES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      console.log(error);
      commit("SET_LOADING", error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_STATES", response.data);
        commit("SET_STATES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveStates(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getStates", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removeStates(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getStates", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch }, payload ) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restoreStates(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteStates(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
