import labels from "@/modules/forms-modules/forms-modules-labels";
export default {
    path: "/forms-modules",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./forms-modules.vue")
}

