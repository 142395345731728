const labels = {
  title: 'Usuário',
  plural: 'Usuários',
  upper: 'USUÁRIO',
  upperPlural: 'USUÁRIOS',
  lower: 'usuário',
  lowerPlural: 'usuários'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    email: {
      label: "Email",
      key: "email",
    },
    document: {
      label: "Documento",
      key: "document",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    peoples: {
      label: "Pessoa",
      key: "name",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira, existem itens vinculados a esse registro`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} `,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    id_people: null,
    email: null,
    document: null,
    password: null,
    confirm_password: null,
    reset_password: "--",
    profiles : []
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    id_people: {
      label: `Nome do ${labels.lower}`,
      description: `Nome do ${labels.lower}`,
      example: "João",
    },
    email: {
      label: "Email",
      description: `Email do ${labels.lower}`,
      example: "abcde@email.com",
    },
    document: {
      label: "Documento",
      description: `Documento do ${labels.lower}`,
      example: "xxx.xxx.xxx-x",
    },
    password: {
      label: "Senha",
      description: `Senha do ${labels.lower}`,
      example: "************",
    },
    confirm_password: {
      label: 'Confirmação de Senha',
      description: 'Confirme sua senha',
      example: 'repita a senha digitada acima'
    },
    reset_password: {
      label: "Reset de senha",
      description: `Reset de senha do ${labels.lower}`,
      example: "************",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
