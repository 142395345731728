import { mountMessage } from "@/utils/MountMessage";
import {
  deleteProductsTypes,
  fetchProductsTypes,
  fetchProductsTypesTaxonomies,
  fetchProductsTypesTrash,
  fetchProductsTypesTypes,
  removeProductsTypes,
  restoreProductsTypes,
  saveProductsTypes,
} from "../services/services";

const state = {
  productsTypes: [],
  isTrash: false,
  taxonomiesTypes: [],
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
  pagination: {},
};

const getters = {
  productsTypes(state) {
    return state.productsTypes;
  },

  isTrash(state) {
    return state.isTrash;
  },

  pagination(state) {
    return state.pagination;
  },

  isLoading(state) {
    return state.loading;
  },

  isFormSaving(state) {
    return state.formLoading;
  },

  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_PRODUCTS_TYPES(state, payload) {
    state.productsTypes = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_PRODUCTS_TYPES_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_PRODUCTS_TYPES_TYPES(state, payload) {
    state.productsTypesTypes = payload;
  },

  SET_TAXONOMIES_TYPES(state, payload) {
    state.taxonomiesTypes = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },
};

const actions = {
  async getProductsTypes({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchProductsTypes(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_PRODUCTS_TYPES", response.data);
        commit("SET_PRODUCTS_TYPES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getProductsTypesTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchProductsTypesTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_PRODUCTS_TYPES", response.data);
        commit("SET_PRODUCTS_TYPES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveProductsTypes(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getProductsTypes", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removeProductsTypes(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getProductsTypes", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restoreProductsTypes(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getProductsTypes", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteProductsTypes(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getProductsTypes", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
