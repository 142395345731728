export default {
  title: 'Tipo de sala física',
  plural: 'Tipos de sala física',
  upper: 'TIPO DE SALA FÍSICA',
  upperPlural: 'TIPOS DE SALA FÍSICA',
  lower: 'tipo de sala física',
  lowerPlural: 'tipos de sala física',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    label: {
      label: "Nome do tipo de sala física",
      key: "label",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },

  },
  erroLoadPaginate: "Erro ao carregar tipos de salas físicas.",
  erroLoadTrash: "Erro ao carregar tipos de salas físicas da lixeira.",
  itemRemovidoLixeira: "Tipo de sala física movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover Tipo de sala física para a lixeira, existem dados vinculados a esse registro, remova os dados para excluir o tipo de sala física.",
  itemExcuidoLixeira: "Tipo de sala física excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir Tipo de sala física da lixeira.",
  itemRestauradoLixeira: "Tipo de sala física restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar tipo de sala física.",
  createTitle: "Novo Tipo de sala física",
  create: "Tipo de sala física criada com sucesso.",
  update: "Tipo de sala física editada com sucesso.",
  updateTitle: "Editar Tipo de sala física",
  createError: "Erro ao criar Tipo de sala física.",
  updateError: "Erro ao editar Tipo de sala física.",
  inputs: {id: null, label: "", identifier: "" },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da tipo de sala física",
      example: "1",
    },
    label: {
      label: "Nome do tipo de sala física",
      description: "Nome do tipo de sala física",
      example: "Matemática",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador do tipo de sala física",
      example: "matematica",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação do tipo de sala física",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização do tipo de sala física",
      example: "2021-01-01 00:00:00",
    }
  }
}
