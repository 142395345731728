import labels from "@/modules/disciplines/disciplines-labels";
export default {
    path: "/disciplines",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./disciplines.vue")
}

