 const labels = {
  title: 'Tipos de avaliações',
  plural: 'Tipos de avaliações',
  upper: 'TIPO DE AVALIAÇÃO',
  upperPlural: 'TIPOS DE AVALIAÇÕES',
  lower: 'tipo de avaliação',
  lowerPlural: 'tipos de avaliações',
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    label: {
      label: "Nome",
      key: "label",
    },
    status: {
      label: "Status",
      key: "status",
      options: [
        {value: 1, text: "Ativo"},
        {value: 2, text: "Inativo"},
      ]
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    label: null,
    identifier: null,
    status: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    label: {
      label: `Nome do ${labels.lower}`,
      description: `Nome do ${labels.lower}`,
      example: "Ensino Fundamental 1",
    },
    identifier: {
      label: "Identificador",
      description: `Identificador do ${labels.lower}`,
      example: "ensino-fundamental-1",
    },
    status: {
      label: "Status",
      description: `status do ${labels.lower}`,
      options: [
        {value: 1, text: "Ativo"},
        {value: 2, text: "Inativo"},
      ]
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
