export default {
  title: 'Curso',
  plural: 'Cursos',
  upper: 'CURSO',
  upperPlural: 'CURSOS',
  lower: 'curso',
  lowerPlural: 'cursos',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    name: {
      label: "Nome",
      key: "name",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    duration: {
      label: "Duração",
      key: "duration",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    levels: {
      label: "Nível",
      key: "label",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados dos cursos.",
  erroLoadTrash: "Erro ao carregar dados dos cursos da lixeira.",
  itemRemovidoLixeira: "Curso movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover o curso para a lixeira, existem dados vinculados a esse registro.",
  itemExcuidoLixeira: "Curso excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir o curso da lixeira.",
  itemRestauradoLixeira: "Cursi restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar o curso.",
  createTitle: "Nova disciplina",
  create: "Curso criado com sucesso.",
  update: "Curso editado com sucesso.",
  updateTitle: "Editar disciplina",
  createError: "Erro ao criar curso.",
  updateError: "Erro ao editar a curdo.",
  inputs: {id: null,
    name: null,
    identifier: null,
    description: null,
    duration: null,
    id_level: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID do Curso",
      example: "1",
    },
    name: {
      label: "Nome",
      description: "Nome do Curso",
      example: "Curso de Matemática",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador do Curso",
      example: "MAT",
    },
    description: {
      label: "Descrição",
      description: "Descrição do Curso",
      example: "Curso de Matemática",
    },
    duration: {
      label: "Duração",
      description: "Duração do Curso",
      example: "4",
    },
    id_level: {
      label: "Nível",
      description: "Nível do Curso",
      example: "1",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação do curso",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização do curso",
      example: "2021-01-01 00:00:00",
    }
  }
}
