import labels from "@/modules/peoples-specialties/peoples-specialties-labels";
export default {
    path: "/peoples-specialties",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./peoples-specialties.vue")
}

