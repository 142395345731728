import {coursesCreate, coursesDeletex, coursesFetch, coursesPaginate, coursesRemove, coursesRestore, coursesTrash, coursesUpdate} from "@/modules/courses/courses-service";

const state = {
  courses: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getCourses(state) {
    const result = state.courses.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_COURSES(state, courses) {
    state.courses = courses;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getCourses({commit}) {
    try {
      const response = await coursesFetch(idSchool);
      const {data} = response;
      commit("SET_COURSES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async coursesCreate({commit, dispatch}, payload) {
    try {
      //console.log('coursesCreate',payload);
      const response = await coursesCreate(idSchool, payload);
      dispatch("getCourses", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async coursesUpdate({commit, dispatch}, payload) {
    try {
      const response = await coursesUpdate(idSchool, payload);
      dispatch("getCourses", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async coursesDeletex({commit}, query) {
    try {
      const response = await coursesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async coursesPaginate({commit}, query) {
    try {
      const response = await coursesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async coursesRemove({commit}, payload) {
    try {
      const response = await coursesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async coursesTrash({commit}, query) {
    try {
      const response = await coursesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async coursesRestore({commit}, payload) {
    try {
      const response = await coursesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
