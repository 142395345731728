import labels from "@/modules/courses/courses-labels";
export default {
    path: "/courses",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./courses.vue")
}

