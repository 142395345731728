import labels from "@/modules/cards-levels/cards-levels-labels";
export default {
    path: "/cards-levels",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./cards-levels.vue")
}

