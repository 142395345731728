import {
  deleteGatewaysPayments,
  fetchAllGateways,
  fetchGatewaysPayments,
  fetchTrash,
  removeGatewaysPayments,
  restoreGatewaysPayments,
  saveGatewaysPayments,
} from "../services/services";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  gatewaysPayments: [],
  allGatewaysPayments: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  gatewaysPayments(state) {
    return state.gatewaysPayments;
  },
  gatewaysPaymentsOptions(state) {
    return state.allGatewaysPayments.map((el) => {
      return {
        text: el.name,
        value: el.id,
      };
    });
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_GATEWAYS_PAYMENTS(state, payload) {
    state.gatewaysPayments = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_GATEWAYS_PAYMENTS_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },

  SET_ALL_GATEWAYS_PAYMENTS(state, payload) {
    state.allGatewaysPayments = payload;
  },
};

const actions = {
  async getGatewaysPayments({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchGatewaysPayments(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_GATEWAYS_PAYMENTS", response.data);
        commit("SET_GATEWAYS_PAYMENTS_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getAllGatewaysPayments({ commit }) {
    try {
      const response = await fetchAllGateways();

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else commit("SET_ALL_GATEWAYS_PAYMENTS", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_GATEWAYS_PAYMENTS", response.data);
        commit("SET_GATEWAYS_PAYMENTS_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch, getters }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveGatewaysPayments(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getGatewaysPayments", {});
        else dispatch("getTrash", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removeGatewaysPayments(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getGatewaysPayments", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch, getters }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restoreGatewaysPayments(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getGatewaysPayments", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteGatewaysPayments(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getGatewaysPayments", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
