const labels = {
  title: 'Comentário da solicitação',
  plural: 'Comentários da solicitação',
  upper: 'COMENTÁRIO DA SOLICITAÇÃO',
  upperPlural: 'COMENTÁRIOS DA SOLICITAÇÃO',
  lower: 'comentário da solicitação',
  lowerPlural: 'comentários da solicitação'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    comment: {
      label: "Comentário",
      key: "comment",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    cards: {
      label: "Titulo",
      key: "title",
    },
    peoples: {
      label: "Pessoa",
      key: "name",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    id_card: null,
    id_people: null,
    comment: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    id_card: {
      label: `Card de ${labels.lower}`,
      description: `Card do ${labels.lower}`,
      example: "Ensino Fundamental 1",
    },
    id_people: {
      label: "Pessoa",
      description: `Pessoa do ${labels.lower}`,
      example: "ensino fundamental 1",
    },
    comment: {
      label: "Comentário",
      description: `Comentário do ${labels.lower}`,
      example: "Texto",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
