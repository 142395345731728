import axios from "axios";

const url = `${process.env.VUE_APP_DOMAIN}/v1_post`;
const url2 = `${process.env.VUE_APP_DOMAIN}/v2_post`;

export const fetchPostTypes = async (idSchool) => {
    try {
        const response = await axios({
            method: "get",
            url: `${url}/v1/${idSchool}/post/type`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const findPostTypes = async (idSchool, payload) => {
    try {
        const response = await axios({
            method: "get",
            url: `${url2}/v1/${idSchool}/posts_types/${payload.id}`,
        });
        return response.data;
    } catch (error) {
        return error;
    }
}

export const createPostTypes = async (idSchool, idUser, payload) => {
    try {
        const response = await axios({
            method: "post",
            url: `${url}/v1/${idSchool}/post/type`,
            data: {
                id_user: idUser,
                label_singular: payload.label_singular,
                label_plural: payload.label_plural,
                type: payload.type,
            },
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const updatePostTypes = async (idSchool, idUser, payload) => {
    try {
        const response = await axios({
            method: "put",
            url: `${url}/v1/${idSchool}/post/type`,
            data: {
                id: payload.id,
                id_user: idUser,
                label_plural: payload.label_singular,
                label_singular: payload.label_plural,
                type: payload.type,
            },
        });
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const deletePostTypes = async (idSchool, idUser, payload) => {
    try {
        const response = await axios({
            method: "delete",
            url: `${url}/v1/${idSchool}/post/type/delete`,
            data: {
                id: payload.id,
                id_user: idUser,
                label_plural: payload.label_singular,
                label_singular: payload.label_plural,
                type: payload.type,
            },
        });
        return response;
    } catch (error) {
        //console.log("🚀 ~ error:", error);
        return error;
    }
};

export const paginatePostTypes = async (idSchool, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;

        const response = await axios({
            method: "get",
            url: `${url}/v1/${idSchool}/post/type/paginate?${queryString}`,
        });
        return response;
    } catch (error) {
        //console.log("🚀 ~ error:", error);
        return error;
    }
};

export const removePostTypes = async (idSchool, idUser, payload) => {
    try {
        const response = await axios({
            method: "put",
            url: `${url}/v1/${idSchool}/post/type/remove`,
            data: {
                id: payload.id,
                id_user: idUser,
                label_plural: payload.label_singular,
                label_singular: payload.label_plural,
                type: payload.type,
            },
        });
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const getTrash = async (idSchool, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;

        const response = await axios({
            method: "get",
            url: `${url}/v1/${idSchool}/post/type/trash/paginate?${queryString}`,
        });
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const restore = async (idSchool, idUser, payload) => {
    try {
        const response = await axios({
            method: "put",
            url: `${url}/v1/${idSchool}/post/type/restore`,
            data: {
                id: payload.id,
                id_user: idUser,
                label_plural: payload.label_singular,
                label_singular: payload.label_plural,
                type: payload.type,
            },
        });
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};
