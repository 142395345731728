import {profilesCreate, profilesDeletex, profilesFetch, profilesPaginate, profilesRemove, profilesRestore, profilesTrash, profilesUpdate} from "@/modules/profiles/profiles-service";

const state = {
  profiles: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters= {
  getProfiles(state) {
    const result = state.profiles.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_PROFILES(state, profiles) {
    state.profiles = profiles;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getProfiles({commit}) {
    try {
      const response = await profilesFetch(idSchool);
      const {data} = response;
      commit("SET_PROFILES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async profilesCreate({commit, dispatch}, payload) {
    try {
      //console.log('profilesCreate',payload);
      const response = await profilesCreate(idSchool, payload);
      dispatch("getProfiles", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async profilesUpdate({commit, dispatch}, payload) {
    try {
      const response = await profilesUpdate(idSchool, payload);
      dispatch("getProfiles", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async profilesDeletex({commit}, query) {
    try {
      const response = await profilesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async profilesPaginate({commit}, query) {
    try {
      const response = await profilesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async profilesRemove({commit}, payload) {
    try {
      const response = await profilesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async profilesTrash({commit}, query) {
    try {
      const response = await profilesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async profilesRestore({commit}, payload) {
    try {
      const response = await profilesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
