export default {
  title: 'Sala física',
  plural: 'Salas físicas',
  upper: 'SALA FÍSICA',
  upperPlural: 'SALAS FÍSICAS',
  lower: 'sala física',
  lowerPlural: 'salas físicas',
  headers: {
    id : {
      label: "ID",
      key: "id",
    },
    identifier : {
      label: "Identificador",
      key: "identifier",
    },
    label : {
      label: "Nome",
      key: "label",
    },
    capacity : {
      label: "Capacidade",
      key: "capacity",
    },
    size : {
      label: "Tamanho",
      key: "size",
    },
    physical_rooms_types : {
      label: "Tipo de sala",
      key: "label",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados das salas físicas.",
  erroLoadTrash: "Erro ao carregar salas físicas da lixeira.",
  itemRemovidoLixeira: "Salas físicas movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover salas físicas para a lixeira.",
  itemExcuidoLixeira: "Salas físicas excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao exlcuir, existem recusrsos de vinculados a esse registro, remova os rescurso para excluir a sala física.",
  itemRestauradoLixeira: "Sala física restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar sala física.",
  createTitle: "Nova sala física",
  create: "Sala Física criada com sucesso.",
  update: "Sala Física editada com sucesso.",
  updateTitle: "Editar sala física",
  createError: "Erro ao criar sala física.",
  updateError: "Erro ao editar sala física.",
  inputs: {id: null,
    identifier: null,
    label: null,
    id_room_type: null,
    capacity: null,
    size: null,
    width: null,
    height: null,
    length: null,
    number: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da sala física",
      example: "1",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador da sala física",
      example: "1",
    },
    label: {
      label: "Nome",
      description: "Nome da sala física",
      example: "Sala 01",
    },
    id_room_type: {
      label: "Tipo de sala física",
      description: "Tipo de sala física",
      example: "1",
    },
    capacity: {
      label: "Capacidade",
      description: "Capacidade da sala física",
      example: "30",
    },
    size: {
      label: "Tamanho",
      description: "Tamanho da sala física",
      example: "30",
    },
    width: {
      label: "Largura",
      description: "Largura da sala física",
      example: "30",
    },
    height: {
      label: "Altura",
      description: "Altura da sala física",
      example: "30",
    },
    length: {
      label: "Comprimento",
      description: "Comprimento da sala física",
      example: "30",
    },

    number: {
      label: "Número",
      description: "Número da sala física",
      example: "30",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da sala física",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da sala física",
      example: "2021-01-01 00:00:00",
    }
  }
}
