import {payInvoicesDocumentsFiscalCreate, payInvoicesDocumentsFiscalDeletex, payInvoicesDocumentsFiscalFetch, payInvoicesDocumentsFiscalPaginate, payInvoicesDocumentsFiscalRemove, payInvoicesDocumentsFiscalRestore, payInvoicesDocumentsFiscalTrash, payInvoicesDocumentsFiscalUpdate} from "@/modules/pay-invoices-documents-fiscal/pay-invoices-documents-fiscal-service";

const state = {
  payInvoicesDocumentsFiscal: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getPayInvoicesDocumentsFiscal(state) {
    const result = state.payInvoicesDocumentsFiscal.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_PAY_INVOICES_DOCUMENTS_FISCAL(state, payInvoicesDocumentsFiscal) {
    state.payInvoicesDocumentsFiscal = payInvoicesDocumentsFiscal;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getPayInvoicesDocumentsFiscal({commit}) {
    try {
      const response = await payInvoicesDocumentsFiscalFetch(idSchool);
      const {data} = response;
      commit("SET_PAY_INVOICES_DOCUMENTS_FISCAL", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesDocumentsFiscalCreate({commit, dispatch}, payload) {
    try {
      //console.log('payInvoicesDocumentsFiscalCreate',payload);
      const response = await payInvoicesDocumentsFiscalCreate(idSchool, payload);
      dispatch("getPayInvoicesDocumentsFiscal", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesDocumentsFiscalUpdate({commit, dispatch}, payload) {
    try {
      const response = await payInvoicesDocumentsFiscalUpdate(idSchool, payload);
      dispatch("getPayInvoicesDocumentsFiscal", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesDocumentsFiscalDeletex({commit}, query) {
    try {
      const response = await payInvoicesDocumentsFiscalDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesDocumentsFiscalPaginate({commit}, query) {
    try {
      const response = await payInvoicesDocumentsFiscalPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async payInvoicesDocumentsFiscalRemove({commit}, payload) {
    try {
      const response = await payInvoicesDocumentsFiscalRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async payInvoicesDocumentsFiscalTrash({commit}, query) {
    try {
      const response = await payInvoicesDocumentsFiscalTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payInvoicesDocumentsFiscalRestore({commit}, payload) {
    try {
      const response = await payInvoicesDocumentsFiscalRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
