import { fetchAllGateways } from "../../gateways/services/services.js";
import {
  deleteGatewaysPaymentsMethods,
  fetchGatewaysPaymentsMethods,
  saveGatewaysPaymentsMethods,
} from "../services/services.js";
import { mountMessage } from "@/utils/MountMessage";

const localMessage = (data) => {
  if (
    data.response.status &&
    (data.response.status < 200 || data.response.status > 299)
  ) {
    let message;

    if (data.response.data.error_mensage.includes("Duplicate")) {
      message = "Este relacionamento já existe.";
    }

    return { message, status: "error" };
  } else {
    return data;
  }
};

const state = {
  gatewaysPaymentsMethods: [],
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  gatewaysPaymentsMethods(state) {
    return state.gatewaysPaymentsMethods;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_GATEWAYS_PAYMENTS_METHODS(state, payload) {
    state.gatewaysPaymentsMethods = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_GATEWAYS_PAYMENTS_METHODS_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_GATEWAYS_OPTIONS(state, payload) {
    state.gatewaysOptions = payload;
  },
};

const actions = {
  async getGatewaysPaymentsMethods({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchGatewaysPaymentsMethods(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_GATEWAYS_PAYMENTS_METHODS", response.data);
        commit("SET_GATEWAYS_PAYMENTS_METHODS_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getGatewaysOptions({ commit }) {
    try {
      const response = await fetchAllGateways();

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else commit("SET_GATEWAYS_OPTIONS", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async save({ commit, dispatch }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveGatewaysPaymentsMethods(payload);

      const error = localMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getGatewaysPaymentsMethods", {});
        this.getGatewaysPaymentsMethods();
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async delete({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteGatewaysPaymentsMethods(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getGatewaysPaymentsMethods", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
