import {schoolsCreate,  schoolsDeletex, schoolsFetch, schoolsPaginate, schoolsRemove, schoolsRestore, schoolsTrash, schoolsUpdate} from "@/modules/schools/schools-service";

const state = {
    schools: [],
    paginate: {},
    load: null,
};
const idSchool = 1;
const getters = {
    getSchools(state) {
        const result = state.schools.map((item) => ({
            ...item,
            showEdit: false,
        }));

        return result;
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
};

const mutations = {
    SET_SCHOOLS(state, schools) {
        state.schools = schools;
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getSchools({commit}) {
        try {
            const response = await schoolsFetch(idSchool);
            const {data} = response;
            commit("SET_SCHOOLS", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolsCreate({commit, dispatch}, payload) {
        try {
            //console.log('schoolsCreate',payload);
            const response = await schoolsCreate(idSchool, payload);
            dispatch("getSchools", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async schoolsUpdate({commit, dispatch}, payload) {
        try {
            const response = await schoolsUpdate(idSchool, payload);
            dispatch("getSchools", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolsDeletex({commit}, query) {
        try {
            const response = await schoolsDeletex(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolsPaginate({commit}, query) {
        try {
            const response = await schoolsPaginate(idSchool, query);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async schoolsRemove({commit}, payload) {
        try {
            const response = await schoolsRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async schoolsTrash({commit}, query) {
        try {
            const response = await schoolsTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolsRestore({commit}, payload) {
        try {
            const response = await schoolsRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
