export default {
  title: 'Série/Ano do segmento',
  plural: 'Séries/Anos do segmento',
  upper: 'SÉRIE/ANO DO SEGMENTO',
  upperPlural: 'SÉRIES/ANOS DO SEGMENTO',
  lower: 'série/ano do segmento',
  lowerPlural: 'séries/anos do segmento',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    label: {
      label: "Nome do Nivel de educação",
      key: "label",
    },

    order: {
      label: "Ordem",
      key: "order",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    teachings: {
      label: "Educação",
      key: "name",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados das séries/anos dos segmentos.",
  erroLoadTrash: "Erro ao carregar séries/anos dos segmentos das lixeiras.",
  itemRemovidoLixeira: "Série/Ano do segmento removido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover o série/ano do segmento para a lixeira.",
  itemExcuidoLixeira: "Série/ano do segmento excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir o série/ano do segmento  da lixeira.",
  itemRestauradoLixeira: "Série/ ano do segmento  restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar série/ano do segmento.",
  createTitle: "Nova Série/Ano do segmento",
  create: "Série/Ano do segmento criada com sucesso.",
  update: "Série/Ano do segmento editada com sucesso.",
  updateTitle: "Editar série/ano do segmento",
  createError: "Erro ao criar a série/ano do segmento.",
  updateError: "Erro ao editar série/ano do segmento.",
  inputs: {
    id: null,
    identifier: null,
    label: null,
    order: null,
    id_teachings: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID de série/ano do segmento",
      example: "1",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador de série/ano do segmento",
      example: "1˚ série",
    },
    label: {
      label: "Nome Identificador de série/ano do segmento",
      description: "Nome do Identificador de série/ano do segmento",
      example: "1˚ série",
    },
    order: {
      label: "Ordem",
      description: "Ordem de série/ano do segmento",
      example: "1",
    },
    id_teachings: {
      label: "SÉRIE/ANO DO SEGMENTO",
      description: "Série/Ano do segmento",
      example: "1",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da série/ano do segmento",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da série/ano do segmento",
      example: "2021-01-01 00:00:00",
    }
  }
}
