import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";
import loadStorageForms from "../modules/load-storage-forms";
import student from "@/components/student/StudentStore";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    ...modules,
    ...loadStorageForms,
    student,
  },
  strict: process.env.DEV,
});

export default store;
