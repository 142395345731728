import labels from "@/modules/posts-configs/posts-configs-labels";
export default {
    path: "/posts-configs",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./posts-configs.vue")
}

