const labels = {
  title: 'Mês',
  plural: 'Meses',
  upper: 'MÊS',
  upperPlural: 'MESES',
  lower: 'mês',
  lowerPlural: 'meses'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    month: {
      label: "Mês",
      key: "month",
    },
    number: {
      label: "Número",
      key: "number",
    },
    label: {
      label: "Nome",
      key: "label",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    years: {
      label: "Ano",
      key: "year",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira, existem itens vinculados a esse registro, exemplo:Níveis, Níveis de capacidade e Grades.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    month: null,
    number: null,
    label: null,
    id_year: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    month: {
      label: `${labels.lower}`,
      description: `Número do ${labels.lower}`,
      example: "Exemplo: 01",
    },
    number: {
      label: "Número",
      description: `Número do ${labels.lower}`,
      example: "Exemplo: 01",
    },
    label: {
      label: "Nome",
      description: `Nome do ${labels.lower}`,
      example: "Exemplo: Janeiro",
    },
    id_year: {
      label: "Ano",
      description: `Ano do ${labels.lower}`,
      example: "Exemplo: 2024",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
