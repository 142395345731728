import Axios from "axios";

const REQUEST =
  `${process.env.VUE_APP_DOMAIN}/v1_payments/v1/1/pay/gateways/settings`;

const axiosInstance = Axios.create({
  baseURL: REQUEST,
});

export const fetchGatewaysPaymentsConfig = async ({
  search = "",
  page = 1,
  perPage = 10,
}) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/paginate${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchTrash = async ({ search = "", page = 1, perPage = 10 }) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `/trash/paginate${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const saveGatewaysPaymentsConfig = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: payload.id ? "put" : "post",
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const removeGatewaysPaymentsConfig = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `/remove`,
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const restoreGatewaysPaymentsConfig = async (id) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: `/restore`,
      data: {
        id: id,
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteGatewaysPaymentsConfig = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: "delete",
      url: `/delete`,
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};
