import labels from "@/modules/curriculums/curriculums-labels";
export default {
    path: "/curriculums",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./curriculums.vue")
}

