export default {
  title: 'Perfil',
  plural: 'Perfis',
  upper: 'PERFIL',
  upperPlural: 'PERFIS',
  lower: 'perfil',
  lowerPlural: 'perfis',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    label: {
      label: "Nome do perfil",
      key: "label",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados dos perfis.",
  erroLoadTrash: "Erro ao carregar perfis das lixeiras.",
  itemRemovidoLixeira: "Perfil removido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover o perfil para a lixeira.",
  itemExcuidoLixeira: "Perfil excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir o perfil  da lixeira.",
  itemRestauradoLixeira: "Perfil restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar perfil.",
  createTitle: "Nova Perfil",
  create: "Perfil criado com sucesso.",
  update: "Perfil editado com sucesso.",
  updateTitle: "Editar perfil",
  createError: "Erro ao criar perfil.",
  updateError: "Erro ao editar perfil.",
  inputs: {
    id: null,
    identifier: null,
    label: null,
    policies:[],
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID de perfil",
      example: "1",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador do perfil",
      example: "Administrador",
    },
    label: {
      label: "Nome Identificador do Perfil",
      description: "Nome do Identificador do perfil",
      example: "Administrador",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da perfil",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da perfil",
      example: "2021-01-01 00:00:00",
    }
  }
}
