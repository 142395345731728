import Axios from "axios";

const domain = process.env.VUE_APP_DOMAIN;
const name = "curriculums";

const axiosInstance = Axios.create({
  baseURL: domain + "/v1_pedagogico"
});

export const curriculumsFetch = async (id_school) => {
  try {
    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const curriculumsFind = async (id_school,payload) => {
  try {
    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}/${payload.id}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const curriculumsCreate = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "post",
      url: `v1/${id_school}/${name}/create`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const curriculumsUpdate = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "put",
      url: `v1/${id_school}/${name}/update/${payload.id}`,
      data: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const curriculumsDeletex = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "delete",
      url: `v1/${id_school}/${name}/delete/${payload.id}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const curriculumsPaginate = async (id_school, payload) => {
  try {
    const queryString = payload.search
      ? `page=${payload.numPage}&search=${payload.search}`
      : `page=${payload.numPage}`;

    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}/paginate`,
      params: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const curriculumsRemove = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "delete",
      url: `v1/${id_school}/${name}/remove/${payload.id}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const curriculumsTrash = async (id_school, payload) => {
  try {
    const queryString = payload.search
      ? `page=${payload.numPage}&search=${payload.search}`
      : `page=${payload.numPage}`;
    const response = await axiosInstance({
      method: "get",
      url: `v1/${id_school}/${name}/trash`,
      params: payload,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const curriculumsRestore = async (id_school, payload) => {
  try {
    const response = await axiosInstance({
      method: "put",
      url: `v1/${id_school}/${name}/restore/${payload.id}`,
      data: {id: payload.id},
    });
    return response;
  } catch (error) {
    return error;
  }
};
