import {schoolEnrollmentsCreate, schoolEnrollmentsDeletex, schoolEnrollmentsFetchFilter, schoolEnrollmentsFetch, schoolEnrollmentsPaginate, schoolEnrollmentsPaginateFilter, schoolEnrollmentsRemove, schoolEnrollmentsRestore, schoolEnrollmentsTrash, schoolEnrollmentsUpdate} from "@/modules/schools-enrollments/schools-enrollments-service";

const state = {
    schoolEnrollments: [],
    paginate: {},
    load: null,
};
const idSchool = 1;
const getters = {
    getSchoolEnrollments(state) {

        try {
            const result = state.schoolEnrollments.map((item) => ({
                ...item,
                showEdit: false,
                chamada: true,
            }));

            return result;
        } catch (e) {
            return [];
        }
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
};

const mutations = {
    SET_schools_enrollments(state, schoolEnrollments) {
        try {
            state.schoolEnrollments = schoolEnrollments;
        } catch (e) {
        }
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getSchoolEnrollments({commit}, query) {
        try {
            const response = await schoolEnrollmentsFetchFilter(idSchool, query);
            const {data} = response;
            commit("SET_schools_enrollments", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolEnrollmentsCreate({commit, dispatch}, payload) {
        try {
            //console.log('schoolEnrollmentsCreate',payload);
            const response = await schoolEnrollmentsCreate(idSchool, payload);
            dispatch("getSchoolEnrollments", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolEnrollmentsUpdate({commit, dispatch}, payload) {
        try {
            const response = await schoolEnrollmentsUpdate(idSchool, payload);
            dispatch("getSchoolEnrollments", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolEnrollmentsDeletex({commit}, query) {
        try {
            const response = await schoolEnrollmentsDeletex(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolEnrollmentsPaginate({commit}, query) {
        try {
            const response = await schoolEnrollmentsPaginateFilter(idSchool, query);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async schoolEnrollmentsRemove({commit}, payload) {
        try {
            const response = await schoolEnrollmentsRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async schoolEnrollmentsTrash({commit}, query) {
        try {
            const response = await schoolEnrollmentsTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async schoolEnrollmentsRestore({commit}, payload) {
        try {
            const response = await schoolEnrollmentsRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
