import {periodicTypesCreate, periodicTypesDeletex, periodicTypesFetch, periodicTypesPaginate, periodicTypesRemove, periodicTypesRestore, periodicTypesTrash, periodicTypesUpdate} from "@/modules/periodic-types/periodic-types-service";

const state = {
  periodicTypes: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getPeriodicTypes(state) {
    const result = state.periodicTypes.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_PERIODIC_TYPES(state, periodicTypes) {
    state.periodicTypes = periodicTypes;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getPeriodicTypes({commit}) {
    try {
      const response = await periodicTypesFetch(idSchool);
      const {data} = response;
      commit("SET_PERIODIC_TYPES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodicTypesCreate({commit, dispatch}, payload) {
    try {
      //console.log('periodicTypesCreate',payload);
      const response = await periodicTypesCreate(idSchool, payload);
      dispatch("getPeriodicTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodicTypesUpdate({commit, dispatch}, payload) {
    try {
      const response = await periodicTypesUpdate(idSchool, payload);
      dispatch("getPeriodicTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodicTypesDeletex({commit}, query) {
    try {
      const response = await periodicTypesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodicTypesPaginate({commit}, query) {
    try {
      const response = await periodicTypesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async periodicTypesRemove({commit}, payload) {
    try {
      const response = await periodicTypesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async periodicTypesTrash({commit}, query) {
    try {
      const response = await periodicTypesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async periodicTypesRestore({commit}, payload) {
    try {
      const response = await periodicTypesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
