import {schoolsTypesCreate, schoolsTypesDeletex, schoolsTypesFetch, schoolsTypesPaginate, schoolsTypesRemove, schoolsTypesRestore, schoolsTypesTrash, schoolsTypesUpdate} from "@/modules/schools-types/schools-types-service";

const state = {
  schoolsTypes: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getSchoolsTypes(state) {
    const result = state.schoolsTypes.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_SCHOOLS_TYPES(state, schoolsTypes) {
    state.schoolsTypes = schoolsTypes;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getSchoolsTypes({commit}) {
    try {
      const response = await schoolsTypesFetch(idSchool);
      const {data} = response;
      commit("SET_SCHOOLS_TYPES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async schoolsTypesCreate({commit, dispatch}, payload) {
    try {
      //console.log('schoolsTypesCreate',payload);
      const response = await schoolsTypesCreate(idSchool, payload);
      dispatch("getSchoolsTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async schoolsTypesUpdate({commit, dispatch}, payload) {
    try {
      const response = await schoolsTypesUpdate(idSchool, payload);
      dispatch("getSchoolsTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async schoolsTypesDeletex({commit}, query) {
    try {
      const response = await schoolsTypesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async schoolsTypesPaginate({commit}, query) {
    try {
      const response = await schoolsTypesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async schoolsTypesRemove({commit}, payload) {
    try {
      const response = await schoolsTypesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async schoolsTypesTrash({commit}, query) {
    try {
      const response = await schoolsTypesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async schoolsTypesRestore({commit}, payload) {
    try {
      const response = await schoolsTypesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
