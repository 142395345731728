import labels from "@/modules/months/months-labels";
export default {
    path: "/months",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./months.vue")
}

