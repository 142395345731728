import {fetchTaxonomiesTypes} from "@/services/taxonomiesTypes";
import {mountMessage} from "@/utils/MountMessage";
import {
    deletePost,
    fetchPosts,
    fetchPostsTrash,
    fetchPostsTypes,
    removePost,
    restorePost,
    savePost,
} from "../services/services";
import formatToOptions from "../utils/formatToOptions";

const state = {
    posts: [],
    postsTypes: [],
    isTrash: false,
    taxonomiesTypes: [],
    loading: {status: "idle"},
    formLoading: {status: "idle"},
    actionLoading: {status: "idle"},
    pagination: {},
};

const getters = {
    posts(state) {
        return state.posts;
    },

    isTrash(state) {
        return state.isTrash;
    },

    postsParents(state) {
        return formatToOptions(state.posts, "name", "id");
    },

    postsTypes(state) {
        return formatToOptions(state.postsTypes);
    },

    taxonomiesTypes(state) {
        return formatToOptions(state.taxonomiesTypes);
    },

    pagination(state) {
        return state.pagination;
    },

    isLoading(state) {
        return state.loading;
    },

    isFormSaving(state) {
        return state.formLoading;
    },

    isActionLoading(state) {
        return state.actionLoading;
    },
};

const mutations = {
    SET_POSTS(state, payload) {
        state.posts = payload;
    },

    SET_LOADING(state, payload) {
        state.loading = payload;
    },

    SET_SAVE_LOADING(state, payload) {
        state.formLoading = payload;
    },

    SET_ACTION_LOADING(state, payload) {
        state.actionLoading = payload;
    },

    SET_POSTS_PAGINATION(state, payload) {
        state.pagination = payload;
    },

    SET_POSTS_TYPES(state, payload) {
        state.postsTypes = payload;
    },

    SET_TAXONOMIES_TYPES(state, payload) {
        state.taxonomiesTypes = payload;
    },

    SET_IS_TRASH(state, payload) {
        state.isTrash = payload;
    },
};

const actions = {
    async getPosts({commit}, query = {}) {
        commit("SET_LOADING", {status: "loading"});
        try {
            const response = await fetchPosts(query);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                commit("SET_IS_TRASH", false);

                try {
                    const taxonomies = JSON.parse(localStorage.getItem("taxonomies"));
                    response.data.forEach((item) => {
                        taxonomies.forEach((tax) => {
                            item[tax] = [];
                        });
                    });
                } catch (e) {

                }

                commit("SET_POSTS", response.data);
                commit("SET_POSTS_PAGINATION", response.pagination);
                commit("SET_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_LOADING", error);
        }
    },

    async getPostsTrash({commit}, query = {}) {
        commit("SET_LOADING", {status: "loading"});
        try {
            const response = await fetchPostsTrash(query);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                commit("SET_IS_TRASH", true);

                try {
                    const taxonomies = JSON.parse(localStorage.getItem("taxonomies"));
                    response.data.forEach((item) => {
                        taxonomies.forEach((tax) => {
                            item[tax] = [];
                        });
                    });
                } catch (e) {

                }

                commit("SET_POSTS", response.data);
                commit("SET_POSTS_PAGINATION", response.pagination);
                commit("SET_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_LOADING", error);
        }
    },

    async getTypes({commit}) {
        try {
            const response = await fetchPostsTypes();

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else commit("SET_POSTS_TYPES", response.data);
        } catch (error) {
            commit("SET_POSTS_TYPES", []);
        }
    },

    async getTaxonomiesTypes({commit}, idSchool) {
        try {
            const response = await fetchTaxonomiesTypes(idSchool);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else commit("SET_TAXONOMIES_TYPES", response.data.data);
        } catch (error) {
            commit("SET_TAXONOMIES_TYPES", []);
        }
    },

    async send({commit, dispatch}, payload) {
        commit("SET_SAVE_LOADING", {status: "loading"});
        try {
            const response = await savePost(payload);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                dispatch("getPosts", {id_post_type: payload.id_post_type});
                commit("SET_SAVE_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_SAVE_LOADING", error);
        }
    },

    async remove({commit, dispatch}, payload) {
        commit("SET_ACTION_LOADING", {status: "loading"});
        try {
            const response = await removePost(payload);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                dispatch("getPosts", {});
                commit("SET_ACTION_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_ACTION_LOADING", error);
        }
    },

    async restore({commit, dispatch}, payload) {
        commit("SET_ACTION_LOADING", {status: "loading"});
        try {
            const response = await restorePost(payload);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                dispatch("getPosts", {});
                commit("SET_ACTION_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_ACTION_LOADING", error);
        }
    },

    async delete({commit, dispatch}, payload) {
        commit("SET_ACTION_LOADING", {status: "loading"});
        try {
            const response = await deletePost(payload);

            const error = mountMessage(response);

            if (error.status === "error") throw error;
            else {
                dispatch("getPosts", {});
                commit("SET_ACTION_LOADING", {status: "ok"});
            }
        } catch (error) {
            commit("SET_ACTION_LOADING", error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
