import labels from "@/modules/physical-rooms-resources/physical-rooms-resources-labels";
export default {
    path: "/physical-rooms-resources",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./physical-rooms-resources.vue")
}

