import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import {error} from "jquery";
import axios from "axios";


class FirebaseAuthBackend {
    constructor(firebaseConfig) {
        this.startFirebaseBackend(firebaseConfig)
    }

    startFirebaseBackend = async (firebaseConfig) => {
        if (firebaseConfig) {
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            await this.getTenant(1);
            firebase.auth().tenantId = localStorage.getItem('tenantId')
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    sessionStorage.setItem("authUser", JSON.stringify(user));
                } else {
                    sessionStorage.removeItem("authUser");
                }
            });
        }
    }

    /**
     * Registers the user with given details
     */
    registerUser = (email, password) => {
        return new Promise(async (resolve, reject) => {
            firebase.auth().tenantId = localStorage.getItem('tenantId')
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(
                    (user) => {
                        // eslint-disable-next-line no-redeclare
                        var user = firebase.auth().currentUser;
                        resolve(user);
                    },
                    (error) => {
                        reject(this._handleError(error));
                    }
                );
        });
    };

    /**
     * Login user with given details
     */
    loginUser = (email, password) => {
        return new Promise(async (resolve, reject) => {
            firebase.auth().tenantId = localStorage.getItem('tenantId')
            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(
                    (user) => {
                        // eslint-disable-next-line no-redeclare
                        var user = firebase.auth().currentUser;

                        console.log('currentUser', user.uid);

                        user.getIdToken().then((token) => {
                            localStorage.setItem("jwtToken", token);
                        });

                        const domain = process.env.VUE_APP_DOMAIN;
                        const url = `${domain}/v1_acls/v1/1/users/permissions/${user.uid}`

                        axios.get(url).then((response) => {
                            const permissions = response.data;
                            const base64 = btoa(JSON.stringify(permissions));
                            localStorage.setItem("permissions", base64);
                            resolve(user);
                        }).catch((error) => {
                            resolve(user);
                        })

                    },
                    (error) => {
                        reject(this._handleError(error));
                    }
                );
        });
    };

    /**
     * forget Password user with given details
     */

    getTenant = (id) => {
        return new Promise  ((resolve, reject) => {
            const domain = process.env.VUE_APP_DOMAIN;
            axios.get(`${domain}/v1_genesis/v1/schools/${id}`).then((response) => {
                console.log('response.data', response.data);
                localStorage.setItem('tenantId', response.data.firebase_auth_tenant_id);
                resolve(response.data);
            }).catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    };

    forgetPassword = (email) => {
        return new Promise(async (resolve, reject) => {
            firebase.auth().tenantId = localStorage.getItem('tenantId')
            firebase
                .auth()
                .sendPasswordResetEmail(email, {
                    url:
                        window.location.protocol + "//" + window.location.host + "/login",
                })
                .then(() => {
                    //console.log("Verdade");
                    resolve(true);
                })
                .catch((error) => {
                    //console.log("Falso");
                    reject(error);
                });
        });
    };

    /**
     * Logout the user
     */
    logout = () => {
        return new Promise(async (resolve, reject) => {
            firebase.auth().tenantId = localStorage.getItem('tenantId')
            firebase
                .auth()
                .signOut()
                .then(() => {
                    resolve(true);
                })
                .catch((error) => {
                    reject(this._handleError(error));
                });
        });
    };

    setLoggeedInUser = (user) => {
        sessionStorage.setItem("authUser", JSON.stringify(user));
    };

    /**
     * Returns the authenticated user
     */
    getAuthenticatedUser = () => {
        if (!sessionStorage.getItem("authUser")) return null;
        return JSON.parse(sessionStorage.getItem("authUser"));
    };

    /**
     * Handle the error
     * @param {*} error
     */
    _handleError(error) {
        var errorMessage = error.message;
        return errorMessage;
    }

    verifyPasswordResetCode = async (code) => {

        return new Promise(async (resolve, reject) => {
            firebase.auth().tenantId = localStorage.getItem('tenantId')
            firebase
                .auth()
                .verifyPasswordResetCode(code)
                .then(
                    () => {
                        resolve(true);
                    },
                    (error) => {
                        reject(this._handleError(error));
                    }
                );
        });
    };

    confirmPasswordReset = (code, newPassword) => {
        return new Promise(async (resolve, reject) => {
            firebase.auth().tenantId = localStorage.getItem('tenantId')
            firebase
                .auth()
                .confirmPasswordReset(code, newPassword)
                .then(
                    () => {
                        resolve(true);
                    },
                    (error) => {
                        reject(this._handleError(error));
                    }
                );
        });
    };
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
    if (!_fireBaseBackend) {
        _fireBaseBackend = new FirebaseAuthBackend(config);
    }
    return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
    return _fireBaseBackend;
};

export {initFirebaseBackend, getFirebaseBackend};
