import formatToOptions from "@/utils/formatToOptions";
import {
  deletePeoplesRaces,
  fetchPeoplesRaces,
  fetchTrash,
  removePeoplesRaces,
  restorePeoplesRaces,
  savePeoplesRaces,
  fetchAllPeoplesRaces,
} from "../services/services";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  peoplesRaces: [],
  allPeoplesRaces: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  peoplesRaces(state) {
    return state.peoplesRaces;
  },
  peoplesRacesOptions(state) {
    return formatToOptions(state.allPeoplesRaces, "label");
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_PEOPLES_RACES(state, payload) {
    state.peoplesRaces = payload;
  },

  SET_ALL_PEOPLES_RACES(state, payload) {
    state.allPeoplesRaces = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_PEOPLES_RACES_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },
};

const actions = {
  async getAllPeoplesRaces({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchAllPeoplesRaces(query);


      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_ALL_PEOPLES_RACES", response.data);
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getPeoplesRaces({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchPeoplesRaces(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_PEOPLES_RACES", response.data);
        commit("SET_PEOPLES_RACES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_PEOPLES_RACES", response.data);
        commit("SET_PEOPLES_RACES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async save({ commit, dispatch, getters }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await savePeoplesRaces(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getPeoplesRaces", {});
        else dispatch("getTrash", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removePeoplesRaces(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getPeoplesRaces", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch, getters }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restorePeoplesRaces(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getPeoplesRaces", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deletePeoplesRaces(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getPeoplesRaces", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
