const labels = {
  title: 'Modelo de Período',
  plural: 'Modelos de Períodos',
  upper: 'MODELO DE PERÍODO',
  upperPlural: 'MODELOS DE PERÍODOS',
  lower: 'modelo de período',
  lowerPlural: 'modelos de períodos',
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    title: {
      label: "Titulo",
      key: "title",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    quantity: {
      label: "Quantidade",
      key: "quantity",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    periodic_types: {
      label: "Tipo de periodo",
      key: "label",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    title: null,
    description: null,
    quantity: null,
    id_periodic_type: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID`,
      example: "1",
    },
    title: {
      label: `Título`,
      description: `Título do modelo`,
      example: "Exemplo: Bimestral",
    },
    description: {
      label: "Descrição",
      description: `Descrição do modelo`,
      example: "Exemplo: Modelo de período bimestral",
    },
    quantity: {
      label: "Quantiade",
      description: `Quantiade`,
      example: `Exemplo: 2`,
    },
    id_periodic_type: {
      label: "Tipo de periodo",
      description: `Tipo de periodo`,
      example: "Bimestral/Semestral/Anual",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização`,
      example: "2021-01-01 00:00:00",
    }
  }
}
