export default {
  title: 'Trilha',
  plural: 'Trilhas',
  upper: 'TRILHA',
  upperPlural: 'TRILHAS',
  lower: 'trilha',
  lowerPlural: 'trilhas',
  headers: [
    "ID",
    "Data de criação",
    "Última Atualização",
    "Nome",
    "Identificador",
    "Sigla",
    "Nome usual",
  ],
  erroLoadPaginate: "Erro ao carregar dados das trilhas.",
  erroLoadTrash: "Erro ao carregar dados das trilhas da  lixeira.",
  itemRemovidoLixeira: "Trilha movida pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover trilha",
  itemExcuidoLixeira: "Trilha excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir trilha da lixeira.",
  itemRestauradoLixeira: "Trilha restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar trilha.",
  createTitle: "Nova trilha",
  create: "Trilha criada com sucesso.",
  update: "Trilha editada com sucesso.",
  updateTitle: "Editar trilha",
  createError: "Erro ao criar trilha.",
  updateError: "Erro ao editar trilha.",
  inputs: {id: null, label: "", identifier: "", acronym: "", usual_name: ""},
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da trilha",
      example: "1",
    },
    label: {
      label: "Nome da trilha",
      description: "Nome da trilha",
      example: "Matemática",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador da trilha",
      example: "matematica",
    },
    acronym: {
      label: "Sigla",
      description: "Sigla da trilha",
      example: "MAT",
    },
    usual_name: {
      label: "Nome usual",
      description: "Nome usual da trilha",
      example: "Matemática",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da trilha",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da trilha",
      example: "2021-01-01 00:00:00",
    }
  }
}
