import formsRoute from "./forms/forms-route";
import formsFieldsRoute from "./forms-fields/forms-fields-route";
import formsFieldsTypesRoute from "./forms-fields-types/forms-fields-types-route"
import formsFieldsValuesRoute from "./forms-fields-values/forms-fields-values-route";
import FormsModulesRoute from "./forms-modules/forms-modules-route";

export default [
	formsRoute,
	formsFieldsRoute,
	formsFieldsTypesRoute,
	formsFieldsValuesRoute,
	FormsModulesRoute,
]
