import labels from "@/modules/assessments_types/assessments_types-labels";
export default {
    path: "/assessments_types",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./assessments_types.vue")
}

