import labels from "@/modules/weeks/weeks-labels";
export default {
    path: "/weeks",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./weeks.vue")
}

