import Axios from "axios";

const REQUEST =
  `${process.env.VUE_APP_DOMAIN}/v1_payments/v1/1/pay/products/types`;

const axiosInstance = Axios.create({
  baseURL: REQUEST,
});

export const fetchProductsTypes = async ({
  search = "",
  page = 1,
  perPage = 10,
}) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `paginate${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const fetchProductsTypesTrash = async ({
  search = "",
  page = 1,
  perPage = 10,
}) => {
  const queryString = search !== "" ? `?search=${search}&` : "?";

  try {
    const { data } = await axiosInstance({
      method: "get",
      url: `trash/paginate${queryString}page=${page}&per_page=${perPage}`,
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const saveProductsTypes = async (payload) => {
  const tags = payload.tags;
  try {
    const { data } = await axiosInstance({
      method: !payload.id ? "post" : "put",
      data: { ...payload },
    });

    if (tags.length > 0) {
      for (let i = 0; i > tags; i++) sendTags(payload.id, tags[i].value);
    }

    return data;
  } catch (error) {
    return error;
  }
};

export const sendTags = async (postId, tagId) => {
  try {
    await axiosInstance({
      method: "post",
      url: "config",
      data: {
        id_post: postId,
        id_taxonomy: tagId,
      },
    });
  } catch (error) {
    return error;
  }
};

export const removeProductsTypes = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: "remove",
      data: { ...payload },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const restoreProductsTypes = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: "put",
      url: "restore",
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};

export const deleteProductsTypes = async (payload) => {
  try {
    const { data } = await axiosInstance({
      method: "delete",
      url: "delete",
      data: { ...payload },
    });

    return data;
  } catch (error) {
    return error;
  }
};
