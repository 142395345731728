import axios from "axios";
const url = `${process.env.VUE_APP_DOMAIN}/v1_post`;

export const fetchTaxonomiesTypes = async (idSchool, idUser) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${url}/v1/${idSchool}/taxonomy/type`,
    });

    return { data };
  } catch (error) {
    return error;
  }
};

export const createTaxonomiesTypes = async (idSchool, idUser, payload) => {
  try {
    const response = await axios({
      method: "post",
      url: `${url}/v1/${idSchool}/taxonomy/type`,
      data: {
        id_user: idUser,
        label_plural: payload.label_singular,
        label_singular: payload.label_plural,
        type: payload.type,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateTaxonomiesTypes = async (idSchool, idUser, payload) => {
  try {
    const response = await axios({
      method: "put",
      url: `${url}/v1/${idSchool}/taxonomy/type`,
      data: {
        id: payload.id,
        id_user: idUser,
        label_plural: payload.label_singular,
        label_singular: payload.label_plural,
        type: payload.type,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteTaxonomiesTypes = async (idSchool, idUser, payload) => {
  try {
    const response = await axios({
      method: "delete",
      url: `${url}/v1/${idSchool}/taxonomy/type/delete`,
      data: {
        id: payload.id,
        id_user: idUser,
        label_plural: payload.label_singular,
        label_singular: payload.label_plural,
        type: payload.type,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const paginateTaxonomiesTypes = async (idSchool, payload) => {
  try {
    const queryString = payload.search
      ? `page=${payload.numPage}&search=${payload.search}`
      : `page=${payload.numPage}`;

    const response = await axios({
      method: "get",
      url: `${url}/v1/${idSchool}/taxonomy/type/paginate?${queryString}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const removeTaxonomiesTypes = async (idSchool, idUser, payload) => {
  try {
    const response = await axios({
      method: "put",
      url: `${url}/v1/${idSchool}/taxonomy/type/remove`,
      data: {
        id: payload.id,
        id_user: idUser,
        label_plural: payload.label_singular,
        label_singular: payload.label_plural,
        type: payload.type,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const getTrash = async (idSchool, payload) => {
  try {
    const queryString = payload.search
      ? `page=${payload.numPage}&search=${payload.search}`
      : `page=${payload.numPage}`;
    const response = await axios({
      method: "get",
      url: `${url}/v1/${idSchool}/taxonomy/type/trash/paginate?${queryString}`,
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const restore = async (idSchool, idUser, payload) => {
  try {
    const response = await axios({
      method: "put",
      url: `${url}/v1/${idSchool}/taxonomy/type/restore`,
      data: {
        id: payload.id,
        id_user: idUser,
        label_plural: payload.label_singular,
        label_singular: payload.label_plural,
        type: payload.type,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
