import {formsCreate, formsDeletex, formsFetch, formsPaginate, formsRemove, formsRestore, formsTrash, formsUpdate} from "@/modules/forms/forms-service";

const state = {
  forms: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getForms(state) {
    const result = state.forms.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_FORMS(state, forms) {
    state.forms = forms;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      //TODO: arrumar
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getForms({commit}) {
    try {
      const response = await formsFetch(idSchool);
      const {data} = response;
      commit("SET_FORMS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsCreate({commit, dispatch}, payload) {
    try {
      //console.log('formsCreate',payload);
      const response = await formsCreate(idSchool, payload);
      dispatch("getForms", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsUpdate({commit, dispatch}, payload) {
    try {
      const response = await formsUpdate(idSchool, payload);
      dispatch("getForms", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsDeletex({commit}, query) {
    try {
      const response = await formsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsPaginate({commit}, query) {
    try {
      const response = await formsPaginate(idSchool, query);

      //console.log('response aqui',response);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async formsRemove({commit}, payload) {
    try {
      const response = await formsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async formsTrash({commit}, query) {
    try {
      const response = await formsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsRestore({commit}, payload) {
    try {
      const response = await formsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
