import labels from "@/modules/classes-trails/classes-trails-labels";
export default {
    path: "/classes-trails",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./classes-trails.vue")
}

