import { fetchAllGateways } from "../../gateways/services/services.js";
import {
  deleteGatewaysPaymentsConfig,
  fetchGatewaysPaymentsConfig,
  fetchTrash,
  removeGatewaysPaymentsConfig,
  restoreGatewaysPaymentsConfig,
  saveGatewaysPaymentsConfig,
} from "../services/services.js";
import { mountMessage } from "@/utils/MountMessage";

const state = {
  configurations: [],
  gatewaysOptions: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  configurations(state) {
    return state.configurations;
  },
  gatewaysOptions(state) {
    return state.gatewaysOptions.map((el) => {
      return {
        text: el.name,
        value: el.id,
      };
    });
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_GATEWAYS_PAYMENTS_CONFIG(state, payload) {
    state.configurations = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_GATEWAYS_PAYMENTS_CONFIG_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },

  SET_GATEWAYS_OPTIONS(state, payload) {
    state.gatewaysOptions = payload;
  },
};

const actions = {
  async getGatewaysPaymentsConfig({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchGatewaysPaymentsConfig(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        commit("SET_GATEWAYS_PAYMENTS_CONFIG", response.data);
        commit("SET_GATEWAYS_PAYMENTS_CONFIG_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_GATEWAYS_PAYMENTS_CONFIG", response.data);
        commit("SET_GATEWAYS_PAYMENTS_CONFIG_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getGatewaysOptions({ commit }) {
    try {
      const response = await fetchAllGateways();

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else commit("SET_GATEWAYS_OPTIONS", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async save({ commit, dispatch }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveGatewaysPaymentsConfig(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getGatewaysPaymentsConfig", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removeGatewaysPaymentsConfig(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getGatewaysPaymentsConfig", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restoreGatewaysPaymentsConfig(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getGatewaysPaymentsConfig", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deleteGatewaysPaymentsConfig(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        dispatch("getGatewaysPaymentsConfig", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
