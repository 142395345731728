import {
    assessments, assessmentsApplied, assessmentsAppliedCreate, assessmentsAppliedUpdate,
    assessmentsCreate,
    assessmentsDelete,
    assessmentsFind,
    assessmentsPaginate,
    assessmentsRemove, assessmentsRestore, assessmentsSchedule, assessmentsScheduleCreate, assessmentsScheduleDelete, assessmentsScheduleReport, assessmentsTrash,
    assessmentsTypes,
    assessmentsUpdate, callsLists,
    callsListsUpdate,
    classContentsCreate,
    classContentsUpdate,
    classesAnnualPlanningCreate,
    classesAnnualPlanningFind,
    classesCreate,
    classesDailys,
    classesDailysCreate,
    classesDailysDelete,
    classesDailysUpdate,
    classesDeletex, classesDisciplines, classesDisciplinesCreate, classesDisciplinesUpdate,
    classesFetch,
    classesGet,
    classesPaginate,
    classesRemove,
    classesRestore,
    classesTrash,
    classesUpdate, reportsCars, reportsCarsCreate, reportsCarsPeriods, reportsCarsPeriodsCreate, reportsCarsPeriodsUpdate, reportsCarsUpdate
} from "@/modules/classes/classes-service";

const state = {
    classes: [],
    classe: {},
    paginate: {},
    load: null,
};
const idSchool = 1;
const getters = {
    getClasses(state) {
        const result = state.classes.map((item) => ({
            ...item,
            showEdit: false,
        }));

        return result;
    },
    getClasse(state) {
        return state.classe;
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
};

const mutations = {
    SET_CLASSES(state, classes) {
        state.classes = classes;
    },
    SET_CLASSE(state, classe) {
        try {
            state.classe = classe;
        } catch (e) {

        }
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getClasses({commit}) {
        try {
            const response = await classesFetch(idSchool);
            const {data} = response;
            commit("SET_CLASSES", data.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async classesCreate({commit, dispatch}, payload) {
        try {
            //console.log('classesCreate', payload);
            const response = await classesCreate(idSchool, payload);
            dispatch("getClasses", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async classesGet({commit}, id) {
        try {
            const response = await classesGet(idSchool, id);
            commit("SET_CLASSE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async classesUpdate({commit, dispatch}, payload) {
        try {
            const response = await classesUpdate(idSchool, payload);
            dispatch("getClasses", idSchool);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async classesDeletex({commit}, query) {
        try {
            const response = await classesDeletex(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async classesPaginate({commit}, query) {
        try {
            const response = await classesPaginate(idSchool, query);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async classesRemove({commit}, payload) {
        try {
            const response = await classesRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async classesTrash({commit}, query) {
        try {
            const response = await classesTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async classesRestore({commit}, payload) {
        try {
            const response = await classesRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    // classesAnnualPlanningCreate
    async classesAnnualPlanningCreate({commit}, payload) {
        return await classesAnnualPlanningCreate(idSchool, payload);
    },
    // classesAnnualPlanningFind
    async classesAnnualPlanningFind({commit}, payload) {
        return await classesAnnualPlanningFind(idSchool, payload);
    },
    //classesDailys
    async classesDailys({commit}, payload) {
        try {
            return await classesDailys(idSchool, payload);
        } catch (error) {
            console.log(error);
        }
    },
    // classesDailysCreate
    async classesDailysCreate({commit}, payload) {
        return await classesDailysCreate(idSchool, payload);
    },
    // classesDailysUpdate
    async classesDailysUpdate({commit}, payload) {
        return await classesDailysUpdate(idSchool, payload);
    },
    // classesDailysDelete
    async classesDailysDelete({commit}, payload) {
        return await classesDailysDelete(idSchool, payload);
    },
    //callsLists
    async callsLists({commit}, payload) {
        return callsLists(idSchool, payload);
    },
    //assessmentsApplied
    async assessmentsApplied({commit}, payload) {
        return assessmentsApplied(idSchool, payload);
    },
    // callsListsUpdate
    async callsListsUpdate({commit}, payload) {
        return callsListsUpdate(idSchool, payload);
    },
    // classContentsCreate
    async classContentsCreate({commit}, payload) {
        return classContentsCreate(idSchool, payload);
    },
    // classContentsUpdate
    async classContentsUpdate({commit}, payload) {
        return classContentsUpdate(idSchool, payload);
    },
    // assessmentsTypes
    async assessmentsTypes({commit}, payload) {
        return assessmentsTypes(idSchool, payload);
    },
    // assessments
    async assessments({commit}, payload) {
        return assessments(idSchool, payload);
    },
    //assessmentsSchedule
    async assessmentsSchedule({commit}, payload) {
        return assessmentsSchedule(idSchool, payload);
    },
    //assessmentsScheduleDelete
    async assessmentsScheduleDelete({commit}, payload) {
        return assessmentsScheduleDelete(idSchool, payload);
    },
    //assessmentsScheduleCreate
    async assessmentsScheduleCreate({commit}, payload) {
        return assessmentsScheduleCreate(idSchool, payload);
    },
    // assessmentsAppliedCreate
    async assessmentsAppliedCreate({commit}, payload) {
        return assessmentsAppliedCreate(idSchool, payload);
    },
    //assessmentsScheduleReport
    async assessmentsScheduleReport({commit}, payload) {
        return assessmentsScheduleReport(idSchool, payload);
    },
    //reportsCarsPeriods
    async reportsCarsPeriods({commit}, payload) {
        return reportsCarsPeriods(idSchool, payload);
    },
    //reportsCarsPeriodsCreate
    async reportsCarsPeriodsCreate({commit}, payload) {
        return reportsCarsPeriodsCreate(idSchool, payload);
    },
    //reports_cars
    async reportsCars({commit}, payload) {
        return reportsCars(idSchool, payload);
    },
    //reportsCarsCreate
    async reportsCarsCreate({commit}, payload) {
        return reportsCarsCreate(idSchool, payload);
    },
    //reportsCarsUpdate
    async reportsCarsUpdate({commit}, payload) {
        return reportsCarsUpdate(idSchool, payload);
    },
    //reportsCarsPeriodsUpdate
    async reportsCarsPeriodsUpdate({commit}, payload) {
        return reportsCarsPeriodsUpdate(idSchool, payload);
    },
    // assessmentsAppliedUpdate
    async assessmentsAppliedUpdate({commit}, payload) {
        return assessmentsAppliedUpdate(idSchool, payload);
    },
    // assessmentsCreate
    async assessmentsCreate({commit}, payload) {
        return assessmentsCreate(idSchool, payload);
    },
    // assessmentsUpdate
    async assessmentsUpdate({commit}, payload) {
        return assessmentsUpdate(idSchool, payload);
    },
    // assessmentsDelete
    async assessmentsDelete({commit}, payload) {
        return assessmentsDelete(idSchool, payload);
    },
    // assessmentsFind
    async assessmentsFind({commit}, payload) {
        return assessmentsFind(idSchool, payload);
    },
    // assessmentsPaginate
    async assessmentsPaginate({commit}, payload) {
        return assessmentsPaginate(idSchool, payload);
    },
    // assessmentsRemove
    async assessmentsRemove({commit}, payload) {
        return assessmentsRemove(idSchool, payload);
    },
    // assessmentsTrash
    async assessmentsTrash({commit}, payload) {
        return assessmentsTrash(idSchool, payload);
    },
    // assessmentsRestore
    async assessmentsRestore({commit}, payload) {
        return assessmentsRestore(idSchool, payload);
    },
    //classes_disciplines
    async classesDisciplines({commit}, payload) {
        return classesDisciplines(idSchool, payload);
    },
    //classes_disciplines create
    async classesDisciplinesCreate({commit}, payload) {
        return classesDisciplinesCreate(idSchool, payload);
    },
    //classes_disciplines update
    async classesDisciplinesUpdate({commit}, payload) {
        return classesDisciplinesUpdate(idSchool, payload);
    }
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
