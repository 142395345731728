export default {
  title: 'Área de conhecimento',
  plural: 'Áreas de conhecimento',
    upper: 'ÁREA DE CONHECIMENTO',
  upperPlural: 'ÁREAS DE CONHECIMENTO',
  lower: 'área de conhecimento',
  lowerPlural: 'áreas de conhecimento',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    label: {
      label: "Nome da área de conhecimento",
      key: "label",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados das áreas.",
  erroLoadTrash: "Erro ao carregar áreas das lixeiras.",
  itemRemovidoLixeira: "Área movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover a área para a lixeira.",
  itemExcuidoLixeira: "Área excluída da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir a área da lixeira.",
  itemRestauradoLixeira: "Área restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar área.",
  createTitle: "Nova área de conhecimento",
  create: "Áreas de conhecimentos criada com sucesso.",
  update: "Área de conhecimento editada com sucesso.",
  updateTitle: "Editar área de conhecimento ",
  createError: "Erro ao criar a área de conhecimento .",
  updateError: "Erro ao editar a área de conhecimento.",
  inputs: {id: null, label: "", identifier: "", },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da área de conhecimento",
      example: "1",
    },
    label: {
      label: "Nome da área de conhecimento",
      description: "Nome da área de conhecimento",
      example: "Matemática",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador da área de conhecimento",
      example: "matematica",
    },

    created_at: {
      label: "Data de criação",
      description: "Data de criação da área de conhecimento",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da área de conhecimento",
      example: "2021-01-01 00:00:00",
    }
  }
}
