export default {
  title: 'Disciplina',
  plural: 'Disciplinas',
  upper: 'DISCIPLINA',
  upperPlural: 'DISCIPLINAS',
  lower: 'disciplina',
  lowerPlural: 'disciplinas',
  headers: {
    id: {
      label: "ID",
      key: "id",
    },
    label: {
      label: "Nome da disciplina",
      key: "label",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
  },
  erroLoadPaginate: "Erro ao carregar dados das disciplinas.",
  erroLoadTrash: "Erro ao carregar dados das disciplinas  para lixeira.",
  itemRemovidoLixeira: "Disciolina movida pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover disciplina para a lixeira.",
  itemExcuidoLixeira: "Disciplina excluída da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir disciplina da lixeira.",
  itemRestauradoLixeira: "Disciplina restaurada com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar disciplina.",
  createTitle: "Nova disciplina",
  create: "Disciplina criada com sucesso.",
  update: "Disciplina editada com sucesso.",
  updateTitle: "Editar disciplina",
  createError: "Erro ao criar a disciplina.",
  updateError: "Erro ao editar a disciplina.",
  inputs: {id: null, label: "", identifier: "", acronym: "", usual_name: ""},
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID da disciplina",
      example: "1",
    },
    label: {
      label: "Nome da disciplina",
      description: "Nome da disciplina",
      example: "Matemática",
    },
    identifier: {
      label: "Identificador",
      description: "Identificador da disciplina",
      example: "matematica",
    },
    acronym: {
      label: "Sigla",
      description: "Sigla da disciplina",
      example: "MAT",
    },
    usual_name: {
      label: "Nome usual",
      description: "Nome usual da disciplina",
      example: "Matemática",
    },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da disciplina",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da disciplina",
      example: "2021-01-01 00:00:00",
    }
  }
}
