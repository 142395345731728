const labels = {
  title: 'Faturas e Documentos Fiscais',
  plural: 'Faturas e Documentos Fiscais',
  upper: 'FATURA E DOCUMENTO FISCAL',
  upperPlural: 'FATURAS E DOCUMENTOS FISCAIS',
  lower: 'fatura e documento fiscal',
  lowerPlural: 'faturas e documentos fiscais'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    code: {
      label: "Código",
      key: "code",
    },
    file: {
      label: "Arquivo",
      key: "file",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    pay_invoices: {
      label: "Fiscal",
      key: "value",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    id_pay_invoices: null,
    code: null,
    file: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    id_pay_invoices: {
      label: `Fiscal da ${labels.lower}`,
      description: `Fiscal da ${labels.lower}`,
      example: "Nota NF",
    },
    code: {
      label: "Código",
      description: `Código do ${labels.lower}`,
      example: "010203",
    },
    file: {
      label: "Arquivo",
      description: `Arquivo do ${labels.lower}`,
      example: " nota.pdf",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
