import labels from "@/modules/forms-fields-types/forms-fields-types-labels";
export default {
    path: "/forms-fields-types",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./forms-fields-types.vue")
}

