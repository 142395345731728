import { default as forms } from "./forms/forms-store";
import { default as formsFields } from "./forms-fields/forms-fields-store";
import { default as formsFieldsTypes } from "./forms-fields-types/forms-fields-types-store"
import { default as formsFieldsValues } from "./forms-fields-values/forms-fields-values-store";
import { default as FormsModules } from "./forms-modules/forms-modules-store";

export default {
  forms,
  formsFields,
  formsFieldsTypes,
  formsFieldsValues,
  FormsModules,
};