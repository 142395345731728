export default {
  title: 'Matrícula de aluno',
  plural: 'Matrículas de alunos',
  upper: 'MATRÍCULA DE ALUNO',
  upperPlural: 'MATRÍCULAS DE ALUNOS',
  lower: 'matrícula de aluno',
  lowerPlural: 'matrículas de alunos',

  headers: {
    id : {
      label: "Matrícula",
      key: "id",
    },
    // id_classes_trails : {
    //   label: "ID de Classes de trilhas",
    //   key: "id_classes_trails",
    // },
    // created_at: {
    //   label: "Data de criação",
    //   key: "created_at",
    // },
    // updated_at: {
    //   label: "Última atualização",
    //   key: "updated_at",
    // },
    levels_capacity_per_years : {
      label: "Ano",
      key: "year",
    },
    peoples : {
      label: "Pessoa",
      key: "name",
    },
    classes : {
      label: "Classe",
      key: "label",
    },
},
  erroLoadPaginate: "Erro ao carregar dados das Matrículas de alunos",
  erroLoadTrash: "Erro ao carregar Matrículas de alunos das lixeiras.",
  itemRemovidoLixeira: "Matrícula de aluno movido pra lixeira com sucesso.",
  itemRemovidoLixeiraErro: "Erro ao remover Matrícula de aluno para a lixeira.",
  itemExcuidoLixeira: "Matrícula de aluno excluído da lixeira com sucesso.",
  itemExcuidoLixeiraErro: "Erro ao excluir Matrícula de aluno da lixeira.",
  itemRestauradoLixeira: "Matrícula de aluno restaurado com sucesso.",
  itemRestauradoLixeiraErro: "Erro ao restaurar Matrícula de aluno.",
  createTitle: "Nova Matrícula de aluno",
  create: "Matrícula de aluno criada com sucesso.",
  update: "Matrícula de aluno editada com sucesso.",
  updateTitle: "Editar Matrícula de aluno",
  createError: "Erro ao criar Matrícula de aluno.",
  updateError: "Erro ao editar Matrícula de aluno.",
  inputs: {
    id: null,
    id_levels_capacity_per_year: null,
    id_peoples: null,
    id_classes:null,
    id_classes_trails: null
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: "ID Matrícula de aluno",
      example: "1",
    },
    id_levels_capacity_per_year: {
      label: "Nível de capacidade por ano",
      description: "Nível de capacidade por ano",
      example: "1",
    },
    id_peoples: {
      label: "Pessoa",
      description: "Pessoao",
      example: "1",
    },
    id_classes: {
      label: "Classe",
      description: "Classe",
      example: "1",
    },
    // id_classes_trails: {
    //   label: "Trilha de classe",
    //   description: "Trilha de classe",
    //   example: "1",
    // },
    created_at: {
      label: "Data de criação",
      description: "Data de criação da Matrícula de aluno",
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: "Data da última atualização da Matrícula de aluno",
      example: "2021-01-01 00:00:00",
    }
  }
}
