import labels from "@/modules/cards/cards-labels";
export default {
    path: "/cards",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./cards.vue")
}

