import labels from "@/modules/levels-capacity-per-years/levels-capacity-per-years-labels";
export default {
    path: "/levels-capacity-per-years",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./levels-capacity-per-years.vue")
}

