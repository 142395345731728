import labels from "@/modules/pay-invoices/pay-invoices-labels";
export default {
    path: "/pay-invoices",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./pay-invoices.vue")
}

