const labels = {
  title: 'Método de Pagamento',
  plural: 'Métodos de Pagamentos',
  upper: 'MÉTODO DE PAGAMENTO',
  upperPlural: 'MÉTODOS DE PAGAMENTOS',
  lower: 'método de pagamento',
  lowerPlural: 'métodos de pagamentos'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    name: {
      label: "Nome",
      key: "name",
    },
    identifier: {
      label: "Identificador",
      key: "identifier",
    },
    image: {
      label: "Imagem",
      key: "image",
    },
    description: {
      label: "Descrição",
      key: "description",
    },
    type: {
      label: "Tipo",
      key: "type",
      options: [
        {value: 'CREDIT', text: "Crédito"},
        {value: 'DEBIT', text: "Débito"},
        {value: 'PIX', text: "Pix"},
        {value: 'TRANSFER', text: "Transferência"},
        {value: 'BILLET', text: "Boleto"},
        {value: 'PAYPAL', text: "Paypal"},
      ]
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },

  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    name: null,
    description: null,
    identifier: null,
    image: null,
    type: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID do ${labels.lower}`,
      example: "1",
    },
    name: {
      label: `Nome do ${labels.lower}`,
      description: `Nome do ${labels.lower}`,
      example: "Ensino Fundamental 1",
    },
    description: {
      label: "Descrição",
      description: `Descrição do ${labels.lower}`,
      example: "ensino-fundamental-1",
    },
    identifier: {
      label: "Identificação",
      description: `Identificação do ${labels.lower}`,
      example: "1",
    },
    image: {
      label: "Imagem",
      description: `Imagem do ${labels.lower}`,
      example: "1",
    },
    type: {
      label: "Tipo",
      description: `Tipo do ${labels.lower}`,
      example: "1",
      options: [
        {value: 'CREDIT', text: "Crédito"},
        {value: 'DEBIT', text: "Débito"},
        {value: 'PIX', text: "Pix"},
        {value: 'TRANSFER', text: "Transferência"},
        {value: 'BILLET', text: "Boleto"},
        {value: 'PAYPAL', text: "Paypal"},
      ]
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
