import {monthsCreate, monthsDeletex, monthsFetch, monthsPaginate, monthsRemove, monthsRestore, monthsTrash, monthsUpdate} from "@/modules/months/months-service";

const state = {
  months: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getMonths(state) {
    const result = state.months.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_MONTHS(state, months) {
    state.months = months;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getMonths({commit}) {
    try {
      const response = await monthsFetch(idSchool);
      const {data} = response;
      commit("SET_MONTHS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async monthsCreate({commit, dispatch}, payload) {
    try {
      //console.log('monthsCreate',payload);
      const response = await monthsCreate(idSchool, payload);
      dispatch("getMonths", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async monthsUpdate({commit, dispatch}, payload) {
    try {
      const response = await monthsUpdate(idSchool, payload);
      dispatch("getMonths", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async monthsDeletex({commit}, query) {
    try {
      const response = await monthsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async monthsPaginate({commit}, query) {
    try {
      const response = await monthsPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async monthsRemove({commit}, payload) {
    try {
      const response = await monthsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async monthsTrash({commit}, query) {
    try {
      const response = await monthsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async monthsRestore({commit}, payload) {
    try {
      const response = await monthsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
