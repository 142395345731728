import labels from "@/modules/cards-comments/cards-comments-labels";
export default {
    path: "/cards-comments",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./cards-comments.vue")
}

