import {holidaysCreate, holidaysDeletex, holidaysFetch, holidaysPaginate, holidaysRemove, holidaysRestore, holidaysTrash, holidaysUpdate} from "@/modules/holidays/holidays-service";

const state = {
  holidays: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getHolidays(state) {
    const result = state.holidays.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_HOLIDAYS(state, holidays) {
    state.holidays = holidays;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getHolidays({commit}) {
    try {
      const response = await holidaysFetch(idSchool);
      const {data} = response;
      commit("SET_HOLIDAYS", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async holidaysCreate({commit, dispatch}, payload) {
    try {
      //console.log('holidaysCreate',payload);
      const response = await holidaysCreate(idSchool, payload);
      dispatch("getHolidays", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async holidaysUpdate({commit, dispatch}, payload) {
    try {
      const response = await holidaysUpdate(idSchool, payload);
      dispatch("getHolidays", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async holidaysDeletex({commit}, query) {
    try {
      const response = await holidaysDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async holidaysPaginate({commit}, query) {
    try {
      const response = await holidaysPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async holidaysRemove({commit}, payload) {
    try {
      const response = await holidaysRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async holidaysTrash({commit}, query) {
    try {
      const response = await holidaysTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async holidaysRestore({commit}, payload) {
    try {
      const response = await holidaysRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
