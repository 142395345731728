import {
  allTwo,
  deletePeoples,
  fetchAllPeoples,
  fetchPeoples,
  fetchTrash,
  removePeoples,
  restorePeoples,
  savePeoples, saveTwo,
} from "../services/services";
import { mountMessage } from "@/utils/MountMessage";
import moment from "moment";

const state = {
  peoples: [],
  allPeoples: [],
  isTrash: false,
  pagination: {},
  loading: { status: "idle" },
  formLoading: { status: "idle" },
  actionLoading: { status: "idle" },
};

const getters = {
  peoples(state) {
    return state.peoples;
  },
  peoplesData(state) {
    //console.log("state.peoples", state.peoples);
    return state.peoples.data;
  },
  allPeoples(state) {
    return state.allPeoples;
  },
  peoplesOptions(state) {
    return state.allPeoples.map((el) => {
      return {
        text: el.name,
        value: el.id,
      };
    });
  },
  isTrash(state) {
    return state.isTrash;
  },
  pagination(state) {
    return state.pagination;
  },
  isLoading(state) {
    return state.loading;
  },
  isFormSaving(state) {
    return state.formLoading;
  },
  isActionLoading(state) {
    return state.actionLoading;
  },
};

const mutations = {
  SET_PEOPLES(state, payload) {
    state.peoples = payload;
  },

  SET_LOADING(state, payload) {
    state.loading = payload;
  },

  SET_SAVE_LOADING(state, payload) {
    state.formLoading = payload;
  },

  SET_ACTION_LOADING(state, payload) {
    state.actionLoading = payload;
  },

  SET_PEOPLES_PAGINATION(state, payload) {
    state.pagination = payload;
  },

  SET_IS_TRASH(state, payload) {
    state.isTrash = payload;
  },

  SET_ALL_PEOPLES(state, payload) {
    state.allPeoples = payload;
  },
};

const actions = {
  async getPeoples({ commit }, query) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchPeoples(query);
      //console.log("response", response);

      response.data.forEach((element) => {
        element.date_of_birth = moment(new Date(element.date_of_birth)).format("YYYY-MM-DD")
        //console.log("element.date_of_birth", element.date_of_birth);
      })


      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", false);
        //console.log("response.data", response.data);
        commit("SET_PEOPLES", response.data);
        commit("SET_PEOPLES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async getAllPeoples({ commit }) {
    try {
      const response = await fetchAllPeoples();

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else commit("SET_ALL_PEOPLES", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getTrash({ commit }, query = {}) {
    commit("SET_LOADING", { status: "loading" });
    try {
      const response = await fetchTrash(query);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        commit("SET_IS_TRASH", true);
        commit("SET_PEOPLES", response.data);
        commit("SET_PEOPLES_PAGINATION", response.pagination);
        commit("SET_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_LOADING", error);
    }
  },

  async allTwo ({commit}) {
    try {
      const response = await allTwo();
      return  response.data
    } catch (error) {
      console.log(error);
      return [];
    }
  },

  async save({ commit, dispatch, getters }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await savePeoples(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getPeoples", {});
        else dispatch("getTrash", {});
        commit("SET_SAVE_LOADING", { status: "ok" });
      }
      return response;
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },
  async saveTwo({ commit, dispatch, getters }, payload) {
    commit("SET_SAVE_LOADING", { status: "loading" });
    try {
      const response = await saveTwo(payload.id,payload.payload);

      return response;
    } catch (error) {
      commit("SET_SAVE_LOADING", error);
    }
  },

  async remove({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await removePeoples(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getPeoples", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async restore({ commit, dispatch, getters }, { id }) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await restorePeoples(id);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getPeoples", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },

  async delete({ commit, dispatch, getters }, payload) {
    commit("SET_ACTION_LOADING", { status: "loading" });
    try {
      const response = await deletePeoples(payload);

      const error = mountMessage(response);

      if (error.status === "error") throw error;
      else {
        if (!getters.isTrash) dispatch("getPeoples", {});
        else dispatch("getTrash", {});
        commit("SET_ACTION_LOADING", { status: "ok" });
      }
    } catch (error) {
      commit("SET_ACTION_LOADING", error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
