import {formsModulesCreate, formsModulesDeletex, formsModulesFetch, formsModulesPaginate, formsModulesRemove, formsModulesRestore, formsModulesTrash, formsModulesUpdate} from "@/modules/forms-modules/forms-modules-service";

const state = {
  formsModules: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getFormsModules(state) {
    const result = state.formsModules.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_FORMS_MODULES(state, formsModules) {
    state.formsModules = formsModules;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      //TODO: Arrumar paginata.data
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getFormsModules({commit}) {
    try {
      const response = await formsModulesFetch(idSchool);
      const {data} = response;
      commit("SET_FORMS_MODULES", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsModulesCreate({commit, dispatch}, payload) {
    try {
      //console.log('formsModulesCreate',payload);
      const response = await formsModulesCreate(idSchool, payload);
      dispatch("getFormsModules", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsModulesUpdate({commit, dispatch}, payload) {
    try {
      const response = await formsModulesUpdate(idSchool, payload);
      dispatch("getFormsModules", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsModulesDeletex({commit}, query) {
    try {
      const response = await formsModulesDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsModulesPaginate({commit}, query) {
    try {
      const response = await formsModulesPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async formsModulesRemove({commit}, payload) {
    try {
      const response = await formsModulesRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async formsModulesTrash({commit}, query) {
    try {
      const response = await formsModulesTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async formsModulesRestore({commit}, payload) {
    try {
      const response = await formsModulesRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
