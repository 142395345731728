import {configsCreate, configsCreateFile, configsDeletex, configsFetch, configsPaginate, configsRemove, configsRestore, configsTrash, configsUpdate, configsUpdateFile} from "@/modules/configs/configs-service";

const state = {
    configs: [],
    paginate: {},
    load: null,
};
const idSchool = 1;
const getters = {

    getConfigsAll(state) {
        return state.configs;
    },
    getConfigs(state) {
        try {
            const result = state.configs.map((item) => ({
                ...item
            }));

            return result;
        } catch (e) {
            console.log('Error', e);
            return [];
        }
    },
    getPaginate(state) {
        const data = {...state.paginate};
        return data;
    },
    getLoad(state) {
        return state.load;
    },
};

const mutations = {
    SET_CONFIGS(state, configs) {
        state.configs = configs;
    },
    SET_PAGINATE(state, paginate) {
        state.paginate = {
            page: paginate.page,
            per_page: paginate.limit,
            total: paginate.total,
            total_pages: paginate.total_pages,
            data: paginate.data
        };
    },
    SET_LOAD(state, load) {
        state.load = load;
    },
};

const actions = {
    setLoadNull({commit}) {
        commit("SET_LOAD", null);
    },
    async getConfigs({commit}) {
        try {
            const response = await configsFetch(idSchool);
            const {data} = response.data;
            commit("SET_CONFIGS", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async configsCreate({commit, dispatch}, payload) {
        try {
            //console.log('configsCreate',payload);
            const response = await configsCreate(idSchool, payload);
            return response;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async configsCreateFile({commit, dispatch}, payload) {
        try {
            //console.log('configsCreate',payload);
            const response = await configsCreateFile(idSchool, payload);
            return response;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async configsUpdate({commit, dispatch}, payload) {
        try {
            const response = await configsUpdate(idSchool, payload);
            return response;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async configsUpdateFile({commit, dispatch}, payload) {
        try {
            const response = await configsUpdateFile(idSchool, payload);
            return response;
        } catch (error) {
            console.log(error);
            return false;
        }
    },
    async configsDeletex({commit}, query) {
        try {
            const response = await configsDeletex(idSchool, query);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async configsPaginate({commit}, query) {
        try {
            const response = await configsPaginate(idSchool, query);
            const {data} = response;
            commit("SET_PAGINATE", data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            commit("SET_LOAD", null);
            console.log(error);
        }
    },

    async configsRemove({commit}, payload) {
        try {
            const response = await configsRemove(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },

    async configsTrash({commit}, query) {
        try {
            const response = await configsTrash(idSchool, query);
            commit("SET_PAGINATE", response.data);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
    async configsRestore({commit}, payload) {
        try {
            const response = await configsRestore(idSchool, payload);
            commit("SET_LOAD", response.status);
        } catch (error) {
            console.log(error);
        }
    },
};

export default {
    namespaced: true,
    actions,
    getters,
    state,
    mutations,
};
