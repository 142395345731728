const labels = {
  title: 'Faturas',
  plural: 'Faturas',
  upper: 'FATURA',
  upperPlural: 'FATURAS',
  lower: 'fatura',
  lowerPlural: 'faturas'
}
export default {
  title: labels.title,
  plural: labels.plural,
  upper: labels.upper,
  upperPlural: labels.upperPlural,
  lower: labels.lower,
  lowerPlural: labels.lowerPlural,
  headers: {

    id: {
      label: "ID",
      key: "id",
    },
    value: {
      label: "Valor",
      key: "value",
    },
    start_date: {
      label: "Data Início",
      key: "start_date",
    },
    end_date: {
      label: "Data Final",
      key: "end_date",
    },
    created_at: {
      label: "Data de criação",
      key: "created_at",
    },
    updated_at: {
      label: "Última atualização",
      key: "updated_at",
    },
    pay_status: {
      label: "Status de pagamento",
      key: "label",
    },
    pay: {
      label: "Pagamento",
      key: "total_value",
    },
  },
  erroLoadPaginate: `Erro ao carregar dados de ${labels.lower}.`,
  erroLoadTrash: `Erro ao carregar dados de ${labels.lower} da lixeira.`,
  itemRemovidoLixeira: `${labels.title} movido pra lixeira com sucesso.`,
  itemRemovidoLixeiraErro: `Erro ao remover o ${labels.lower} para a lixeira.`,
  itemExcuidoLixeira: `${labels.title} excluído da lixeira com sucesso.`,
  itemExcuidoLixeiraErro: `Erro ao excluir o ${labels.lower} da lixeira.`,
  itemRestauradoLixeira: `${labels.title} restaurado com sucesso.`,
  itemRestauradoLixeiraErro: `Erro ao restaurar o ${labels.lower}.`,
  createTitle: `Novo ${labels.lower}`,
  create: `${labels.title} criado com sucesso.`,
  update: `${labels.title} editado com sucesso.`,
  updateTitle: `Editar ${labels.lower} disciplina`,
  createError: `Erro ao criar o ${labels.lower}.`,
  updateError: `Erro ao editar a ${labels.lower}.`,
  inputs: {
    id: null,
    value: null,
    id_pay_status: null,
    start_date: null,
    end_date: null,
  },
  inputsInfos: {
    id: {
      label: "ID",
      description: `ID da ${labels.lower}`,
      example: "1",
    },
    value: {
      label: "Valor",
      description: `Valor do ${labels.lower}`,
      example: "99.99",
    },
    id_pay: {
      label: `Pagamento de ${labels.lower}`,
      description: `Pagamento de ${labels.lower}`,
      example: "1",
    },
    id_pay_status: {
      label: `Status de ${labels.lower}`,
      description: `Status de ${labels.lower}`,
      example: "1",
    },
    start_date: {
      label: "Data Início",
      description: `Data Início de ${labels.lower}`,
      example: "1",
    },
    end_date: {
      label: "Data Final",
      description: `Data Final de ${labels.lower}`,
      example: "1",
    },
    created_at: {
      label: "Data de criação",
      description: `Data de criação do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    },
    updated_at: {
      label: "Última atualização",
      description: `Data da última atualização do ${labels.lower}`,
      example: "2021-01-01 00:00:00",
    }
  }
}
