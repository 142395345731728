import {payCreate, payDeletex, payFetch, payPaginate, payRemove, payRestore, payTrash, payUpdate} from "@/modules/pay/pay-service";

const state = {
  pay: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getPay(state) {
    const result = state.pay.data.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_PAY(state, pay) {
    state.pay = pay;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getPay({commit}) {
    try {
      const response = await payFetch(idSchool);
      const {data} = response;
      commit("SET_PAY", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payCreate({commit, dispatch}, payload) {
    try {
      //console.log('payCreate',payload);
      const response = await payCreate(idSchool, payload);
      dispatch("getPay", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payUpdate({commit, dispatch}, payload) {
    try {
      const response = await payUpdate(idSchool, payload);
      dispatch("getPay", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payDeletex({commit}, query) {
    try {
      const response = await payDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payPaginate({commit}, query) {
    try {
      const response = await payPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async payRemove({commit}, payload) {
    try {
      const response = await payRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async payTrash({commit}, query) {
    try {
      const response = await payTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async payRestore({commit}, payload) {
    try {
      const response = await payRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
