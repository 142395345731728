import {classesTrailsCreate, classesTrailsDeletex, classesTrailsFetch, classesTrailsPaginate, classesTrailsRemove, classesTrailsRestore, classesTrailsTrash, classesTrailsUpdate} from "@/modules/classes-trails/classes-trails-service";

const state = {
  classesTrails: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const getters = {
  getClassesTrails(state) {
    const result = state.classesTrails.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = {...state.paginate};
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_CLASSES_TRAILS(state, classesTrails) {
    state.classesTrails = classesTrails;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = {
      page: paginate.page,
      per_page: paginate.limit,
      total: paginate.total,
      total_pages: paginate.total_pages,
      data: paginate.data
    };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({commit}) {
    commit("SET_LOAD", null);
  },
  async getClassesTrails({commit}) {
    try {
      const response = await classesTrailsFetch(idSchool);
      const {data} = response;
      commit("SET_CLASSES_TRAILS", data.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async classesTrailsCreate({commit, dispatch}, payload) {
    try {
      //console.log('classesTrailsCreate',payload);
      const response = await classesTrailsCreate(idSchool, payload);
      dispatch("getClassesTrails", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async classesTrailsUpdate({commit, dispatch}, payload) {
    try {
      const response = await classesTrailsUpdate(idSchool, payload);
      dispatch("getClassesTrails", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async classesTrailsDeletex({commit}, query) {
    try {
      const response = await classesTrailsDeletex(idSchool, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async classesTrailsPaginate({commit}, query) {
    try {
      const response = await classesTrailsPaginate(idSchool, query);
      const {data} = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async classesTrailsRemove({commit}, payload) {
    try {
      const response = await classesTrailsRemove(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async classesTrailsTrash({commit}, query) {
    try {
      const response = await classesTrailsTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async classesTrailsRestore({commit}, payload) {
    try {
      const response = await classesTrailsRestore(idSchool, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
