import Axios from "axios";

const domain = process.env.VUE_APP_DOMAIN;
const name = "configs";

const axiosInstance = Axios.create({
    baseURL: domain + "/v1_settings"
});

export const configsFetch = async (id_school) => {
    try {
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const configsCreate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/${name}/create`,
            data: payload,
        });
        return response;
    } catch (error) {
        return false;
    }
};

export const configsCreateFile = async (id_school, payload) => {
    try {
        const formData = new FormData();
        // formData.set('id',payload.id)
        formData.set('id_user',payload.id_user)
        formData.set('key',payload.key)
        formData.set('value',payload.value)

        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/${name}/create/file`,
            data: formData,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const configsUpdate = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${name}/update/${payload.id}`,
            data: payload,
        });
        console.log('response', response);
        return response;
    } catch (error) {
        return false;
    }
};

export const configsUpdateFile = async (id_school, payload) => {
    try {
        const formData = new FormData();
        formData.set('id',payload.id)
        formData.set('id_user',payload.id_user)
        formData.set('key',payload.key)
        formData.set('value',payload.value)
        const response = await axiosInstance({
            method: "post",
            url: `v1/${id_school}/${name}/update/file/${payload.id}`,
            data: formData,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const configsDeletex = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/${name}/delete/${payload.id}`,
        });
        return response;
    } catch (error) {
        return false;
    }
};

export const configsPaginate = async (id_school, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;

        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/paginate`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const configsRemove = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "delete",
            url: `v1/${id_school}/${name}/remove/${payload.id}`,
        });
        return response;
    } catch (error) {
        return error;
    }
};
export const configsTrash = async (id_school, payload) => {
    try {
        const queryString = payload.search
            ? `page=${payload.numPage}&search=${payload.search}`
            : `page=${payload.numPage}`;
        const response = await axiosInstance({
            method: "get",
            url: `v1/${id_school}/${name}/trash`,
            params: payload,
        });
        return response;
    } catch (error) {
        return error;
    }
};

export const configsRestore = async (id_school, payload) => {
    try {
        const response = await axiosInstance({
            method: "put",
            url: `v1/${id_school}/${name}/restore/${payload.id}`,
            data: {id: payload.id},
        });
        return response;
    } catch (error) {
        return error;
    }
};
