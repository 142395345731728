import {
  fetchTaxonomiesTypes,
  createTaxonomiesTypes,
  updateTaxonomiesTypes,
  deleteTaxonomiesTypes as deleteTaxonomiesTypesService,
  paginateTaxonomiesTypes,
  removeTaxonomiesTypes,
  getTrash,
  restore,
} from "@/services/taxonomiesTypes";

const state = {
  taxonomiesTypes: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const idUser = 1;
const getters = {
  getTaxonomies(state) {
    return state.taxonomiesTypes;
  },
  getTaxonomiesTypes(state) {
    const result = state.taxonomiesTypes.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = { ...state.paginate };
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_TAXONOMIES_TYPE(state, taxonomiesTypes) {
    state.taxonomiesTypes = taxonomiesTypes;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = { ...paginate };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({ commit }) {
    commit("SET_LOAD", null);
  },
  async getTaxonomiesTypes({ commit }) {
    try {
      const response = await fetchTaxonomiesTypes(idSchool, idUser);
      const { data } = response;
      commit("SET_TAXONOMIES_TYPE", data.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async createTaxonomiesTypes({ commit, dispatch }, payload) {
    try {
      const response = await createTaxonomiesTypes(idSchool, idUser, payload);
      dispatch("getTaxonomiesTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    } finally {
      location.reload();
    }
  },
  async updateTaxonomiesTypes({ commit, dispatch }, payload) {
    try {
      const response = await updateTaxonomiesTypes(idSchool, idUser, payload);
      dispatch("getTaxonomiesTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    } finally {
      location.reload();
    }
  },
  async deleteTaxonomiesTypes({ commit }, query) {
    try {
      const response = await deleteTaxonomiesTypesService(
        idSchool,
        idUser,
        query
      );
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    } finally {
      location.reload();
    }
  },
  async getPaginateTaxonomiesTypes({ commit }, query) {
    try {
      const response = await paginateTaxonomiesTypes(idSchool, query);
      const { data } = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async removeTaxonomiesTypes({ commit }, payload) {
    try {
      const response = await removeTaxonomiesTypes(idSchool, idUser, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    } finally {
      location.reload();
    }
  },

  async getTrash({ commit }, query) {
    try {
      const response = await getTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },
  async restore({ commit }, payload) {
    try {
      const response = await restore(idSchool, idUser, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    } finally {
      location.reload();
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
