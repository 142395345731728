import {
  fetchPostTypes,
  createPostTypes,
  updatePostTypes,
  deletePostTypes as deletePostTypesService,
  paginatePostTypes,
  removePostTypes,
  getTrash,
  restore, findPostTypes,
} from "@/services/postTypes";

const state = {
  postTypes: [],
  paginate: {},
  load: null,
};
const idSchool = 1;
const idUser = 1;
const getters = {
  getPostTypes(state) {
    const result = state.postTypes.map((item) => ({
      ...item,
      showEdit: false,
    }));

    return result;
  },
  getPaginate(state) {
    const data = { ...state.paginate };
    return data;
  },
  getLoad(state) {
    return state.load;
  },
};

const mutations = {
  SET_POST_TYPE(state, postTypes) {
    state.postTypes = postTypes;
  },
  SET_PAGINATE(state, paginate) {
    state.paginate = { ...paginate };
  },
  SET_LOAD(state, load) {
    state.load = load;
  },
};

const actions = {
  setLoadNull({ commit }) {
    commit("SET_LOAD", null);
  },
  async getPostTypes({ commit }) {
    try {
      const response = await fetchPostTypes(idSchool);
      const { data } = response;
      commit("SET_POST_TYPE", data.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async findPostType({ commit }, query) {
    try {
      const response = await findPostTypes(idSchool, query);
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  async createPostTypes({ commit, dispatch }, payload) {
    try {
      const response = await createPostTypes(idSchool, idUser, payload);
      dispatch("getPostTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async updatePostTypes({ commit, dispatch }, payload) {
    try {
      const response = await updatePostTypes(idSchool, idUser, payload);
      dispatch("getPostTypes", idSchool);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async deletePostTypes({ commit }, query) {
    try {
      const response = await deletePostTypesService(idSchool, idUser, query);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
  async getPaginatePostTypes({ commit }, query) {
    try {
      const response = await paginatePostTypes(idSchool, query);
      const { data } = response;
      commit("SET_PAGINATE", data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },

  async removePostTypes({ commit }, payload) {
    try {
      const response = await removePostTypes(idSchool, idUser, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },

  async getTrash({ commit }, query) {
    try {
      const response = await getTrash(idSchool, query);
      commit("SET_PAGINATE", response.data);
      commit("SET_LOAD", response.status);
    } catch (error) {
      commit("SET_LOAD", null);
      console.log(error);
    }
  },
  async restore({ commit }, payload) {
    try {
      const response = await restore(idSchool, idUser, payload);
      commit("SET_LOAD", response.status);
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
