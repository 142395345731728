import labels from "@/modules/forms-fields-values/forms-fields-values-labels";
export default {
    path: "/forms-fields-values",
    name: labels.plural,
    meta: {
        authRequired: true,
    },
    component: () => import("./forms-fields-values.vue")
}

